import { createGroup, createRouter, defineRoute, param } from 'type-route';

const project = defineRoute(
  {
    projectId: param.path.string,
  },
  (p: any) => `/projects/${p.projectId}`,
);
const services = defineRoute('/services');
const computeService = services.extend('/compute');
const computeProject = computeService.extend(
  {
    projectId: param.path.string,
  },
  (p: any) => `/projects/${p.projectId}`,
);

export const { RouteProvider, useRoute, routes } = createRouter({
  home: defineRoute('/'),
  user: defineRoute('/user'),
  signUp: defineRoute('/auth/signup'),
  login: defineRoute('/auth/login'),
  logout: defineRoute('/auth/logout'),
  dashboard: project.extend('/dashboard'),
  projectCreate: defineRoute('/projects:create'),
  projectDetail: project.extend('/'),
  applicationList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/applications',
  ),
  applicationCreate: project.extend('/applications:create'),
  applicationDetail: project.extend(
    {
      applicationId: param.path.string,
    },
    (p: any) => `/applications/${p.applicationId}`,
  ),
  incarnationList: project.extend(
    {
      applicationId: param.path.string,
      pageToken: param.query.optional.string,
    },
    (p: any) => `/applications/${p.applicationId}/incarnations`,
  ),
  incarnationDetail: project.extend(
    {
      applicationId: param.path.string,
      incarnationId: param.path.string,
    },
    (p: any) =>
      `/applications/${p.applicationId}/incarnations/${p.incarnationId}`,
  ),
  assetList: project.extend(
    {
      applicationId: param.path.string,
      pageToken: param.query.optional.string,
    },
    (p: any) => `/applications/${p.applicationId}/assets`,
  ),
  assetDetail: project.extend(
    {
      applicationId: param.path.string,
      assetId: param.path.string,
    },
    (p: any) => `/applications/${p.applicationId}/assets/${p.assetId}`,
  ),
  assetYaml: project.extend(
    {
      applicationId: param.path.string,
      assetId: param.path.string,
    },
    (p: any) => `/applications/${p.applicationId}/assets/${p.assetId}/yaml`,
  ),
  rolloutList: project.extend(
    {
      applicationId: param.path.string,
      pageToken: param.query.optional.string,
    },
    (p: any) => `/applications/${p.applicationId}/rollouts`,
  ),
  rolloutDetail: project.extend(
    {
      applicationId: param.path.string,
      rolloutId: param.path.string,
      pageToken: param.query.optional.string,
    },
    (p: any) => `/applications/${p.applicationId}/rollouts/${p.rolloutId}`,
  ),
  rolloutStepList: project.extend(
    {
      applicationId: param.path.string,
      rolloutId: param.path.string,
      pageToken: param.query.optional.string,
    },
    (p: any) =>
      `/applications/${p.applicationId}/rollouts/${p.rolloutId}/steps`,
  ),
  clusterList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/clusters',
  ),
  clusterCreate: project.extend('/clusters:create'),
  clusterDetail: project.extend(
    {
      clusterId: param.path.string,
    },
    (p: any) => `/clusters/${p.clusterId}`,
  ),
  clusterNamespaceList: project.extend(
    {
      clusterId: param.path.string,
    },
    (p: any) => `/clusters/${p.clusterId}/namespaces`,
  ),
  clusterApiResourceList: project.extend(
    {
      clusterId: param.path.string,
    },
    (p: any) => `/clusters/${p.clusterId}/apiResources`,
  ),
  gitRepositoryList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/gitRepositories',
  ),
  gitRepositoryCreate: project.extend('/gitRepositories:create'),
  gitRepositoryDetail: project.extend(
    {
      gitRepositoryId: param.path.string,
    },
    (p: any) => `/gitRepositories/${p.gitRepositoryId}`,
  ),
  sshKeyList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/sshKeys',
  ),
  sshKeyCreate: project.extend('/sshKeys:create'),
  sshKeyDetail: project.extend(
    {
      sshKeyId: param.path.string,
    },
    (p: any) => `/sshKeys/${p.sshKeyId}`,
  ),
  sshKnownHostList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/sshKnownHosts',
  ),
  sshKnownHostCreate: project.extend('/sshKnownHosts:create'),
  sshKnownHostDetail: project.extend(
    {
      sshKnownHostId: param.path.string,
    },
    (p: any) => `/sshKnownHosts/${p.sshKnownHostId}`,
  ),
  ignoreDifferenceList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/ignoreDifferences',
  ),
  ignoreDifferenceCreate: project.extend('/ignoreDifferences:create'),
  ignoreDifferenceDetail: project.extend(
    {
      ignoreDifferenceId: param.path.string,
    },
    (p: any) => `/ignoreDifferences/${p.ignoreDifferenceId}`,
  ),
  roleBindingList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/roleBindings',
  ),
  roleBindingCreate: project.extend('/roleBindings:create'),
  roleBindingDetail: project.extend(
    {
      roleBindingId: param.path.string,
    },
    (p: any) => `/roleBindings/${p.roleBindingId}`,
  ),
  oidcProviderList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/oidcProviders',
  ),
  oidcProviderCreate: project.extend('/oidcProviders:create'),
  oidcProviderDetail: project.extend(
    {
      oidcProviderId: param.path.string,
    },
    (p: any) => `/oidcProviders/${p.oidcProviderId}`,
  ),
  serviceAccountList: project.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/serviceAccounts',
  ),
  serviceAccountCreate: project.extend('/serviceAccounts:create'),
  serviceAccountDetail: project.extend(
    {
      serviceAccountId: param.path.string,
    },
    (p: any) => `/serviceAccounts/${p.serviceAccountId}`,
  ),
  computeClusterList: computeProject.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/clusters',
  ),
  computeClusterCreate: computeProject.extend('/clusters:create'),
  computeClusterDetail: computeProject.extend(
    {
      clusterId: param.path.string,
    },
    (p: any) => `/clusters/${p.clusterId}`,
  ),
  computeMachineList: computeProject.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/machines',
  ),
  computeMachineCreate: computeProject.extend('/machines:create'),
  computeMachineDetail: computeProject.extend(
    {
      machineId: param.path.string,
    },
    (p: any) => `/machines/${p.machineId}`,
  ),
  computeOperationList: computeProject.extend(
    {
      pageToken: param.query.optional.string,
    },
    () => '/operations',
  ),
  computeOperationDetail: computeProject.extend(
    {
      operationId: param.path.string,
    },
    (p: any) => `/operations/${p.operationId}`,
  ),
  projectSettings: project.extend('/settings'),
});

const projectGroup = createGroup([
  routes.projectDetail,
  routes.dashboard,
  routes.applicationList,
  routes.applicationCreate,
  routes.applicationDetail,
  routes.incarnationList,
  routes.incarnationDetail,
  routes.assetList,
  routes.assetDetail,
  routes.assetYaml,
  routes.rolloutList,
  routes.rolloutDetail,
  routes.rolloutStepList,
  routes.clusterList,
  routes.clusterCreate,
  routes.clusterDetail,
  routes.clusterNamespaceList,
  routes.clusterApiResourceList,
  routes.gitRepositoryList,
  routes.gitRepositoryCreate,
  routes.gitRepositoryDetail,
  routes.sshKeyList,
  routes.sshKeyCreate,
  routes.sshKeyDetail,
  routes.sshKnownHostList,
  routes.sshKnownHostCreate,
  routes.sshKnownHostDetail,
  routes.ignoreDifferenceList,
  routes.ignoreDifferenceCreate,
  routes.ignoreDifferenceDetail,
  routes.computeClusterList,
  routes.computeClusterDetail,
  routes.computeClusterCreate,
  routes.computeMachineList,
  routes.computeMachineDetail,
  routes.computeMachineCreate,
  routes.computeOperationList,
  routes.computeOperationDetail,
  routes.roleBindingList,
  routes.roleBindingCreate,
  routes.roleBindingDetail,
  routes.oidcProviderList,
  routes.oidcProviderCreate,
  routes.oidcProviderDetail,
  routes.serviceAccountList,
  routes.serviceAccountCreate,
  routes.serviceAccountDetail,
  routes.projectSettings,
]);

const deliveryGroup = createGroup([
  routes.applicationList,
  routes.applicationCreate,
  routes.applicationDetail,
  routes.incarnationList,
  routes.incarnationDetail,
  routes.assetList,
  routes.assetDetail,
  routes.assetYaml,
  routes.rolloutList,
  routes.rolloutDetail,
  routes.rolloutStepList,
  routes.clusterList,
  routes.clusterCreate,
  routes.clusterDetail,
  routes.clusterNamespaceList,
  routes.clusterApiResourceList,
  routes.gitRepositoryList,
  routes.gitRepositoryCreate,
  routes.gitRepositoryDetail,
  routes.sshKeyList,
  routes.sshKeyCreate,
  routes.sshKeyDetail,
  routes.sshKnownHostList,
  routes.sshKnownHostCreate,
  routes.sshKnownHostDetail,
  routes.ignoreDifferenceList,
  routes.ignoreDifferenceCreate,
  routes.ignoreDifferenceDetail,
]);

const computeGroup = createGroup([
  routes.computeClusterList,
  routes.computeClusterDetail,
  routes.computeClusterCreate,
  routes.computeMachineList,
  routes.computeMachineDetail,
  routes.computeMachineCreate,
  routes.computeOperationList,
  routes.computeOperationDetail,
]);

const iamGroup = createGroup([
  routes.roleBindingList,
  routes.roleBindingCreate,
  routes.roleBindingDetail,
  routes.oidcProviderList,
  routes.oidcProviderCreate,
  routes.oidcProviderDetail,
  routes.serviceAccountList,
  routes.serviceAccountCreate,
  routes.serviceAccountDetail,
]);

const applicationGroup = createGroup([
  routes.applicationList,
  routes.applicationCreate,
  routes.applicationDetail,
  routes.incarnationList,
  routes.incarnationDetail,
  routes.assetList,
  routes.assetDetail,
  routes.assetYaml,
  routes.rolloutList,
  routes.rolloutDetail,
  routes.rolloutStepList,
]);

const applicationDetailGroup = createGroup([
  routes.applicationDetail,
  routes.incarnationList,
  routes.rolloutList,
]);

const assetDetailGroup = createGroup([
  routes.assetDetail,
  routes.assetYaml,
]);

const clusterGroup = createGroup([
  routes.clusterList,
  routes.clusterCreate,
  routes.clusterDetail,
  routes.clusterNamespaceList,
  routes.clusterApiResourceList,
]);

const clusterDetailGroup = createGroup([
  routes.clusterDetail,
  routes.clusterNamespaceList,
  routes.clusterApiResourceList,
]);

const gitRepositoryGroup = createGroup([
  routes.gitRepositoryList,
  routes.gitRepositoryCreate,
  routes.gitRepositoryDetail,
]);

const sshKeyGroup = createGroup([
  routes.sshKeyList,
  routes.sshKeyCreate,
  routes.sshKeyDetail,
]);

const sshKnownHostGroup = createGroup([
  routes.sshKnownHostList,
  routes.sshKnownHostCreate,
  routes.sshKnownHostDetail,
]);

const ignoreDifferenceGroup = createGroup([
  routes.ignoreDifferenceList,
  routes.ignoreDifferenceCreate,
  routes.ignoreDifferenceDetail,
]);

const computeClusterGroup = createGroup([
  routes.computeClusterList,
  routes.computeClusterDetail,
  routes.computeClusterCreate,
]);

const computeMachineGroup = createGroup([
  routes.computeMachineList,
  routes.computeMachineDetail,
  routes.computeMachineCreate,
]);

const computeOperationGroup = createGroup([
  routes.computeOperationList,
  routes.computeOperationDetail,
]);

const iamRoleBindingGroup = createGroup([
  routes.roleBindingList,
  routes.roleBindingCreate,
  routes.roleBindingDetail,
]);

const iamOidcProviderGroup = createGroup([
  routes.oidcProviderList,
  routes.oidcProviderCreate,
  routes.oidcProviderDetail,
]);

const iamServiceAccountGroup = createGroup([
  routes.serviceAccountList,
  routes.serviceAccountCreate,
  routes.serviceAccountDetail,
]);

export const groups = {
  project: projectGroup,
  application: applicationGroup,
  applicationDetail: applicationDetailGroup,
  assetDetail: assetDetailGroup,
  cluster: clusterGroup,
  clusterDetail: clusterDetailGroup,
  gitRepository: gitRepositoryGroup,
  sshKey: sshKeyGroup,
  sshKnownHost: sshKnownHostGroup,
  ignoreDifference: ignoreDifferenceGroup,
  computeCluster: computeClusterGroup,
  computeMachine: computeMachineGroup,
  computeOperation: computeOperationGroup,
  iamRoleBinding: iamRoleBindingGroup,
  iamOidcProvider: iamOidcProviderGroup,
  iamServiceAccount: iamServiceAccountGroup,
  delivery: deliveryGroup,
  compute: computeGroup,
  iam: iamGroup,
};
