import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { OIDCProviderDeleteModal } from './oidc_provider_delete_modal';
import { OIDCProviderInfo } from './oidc_provider_info';

interface OIDCProviderDetailProps {
  route: Route<typeof routes.oidcProviderDetail>;
}

export function OIDCProviderDetail(props: OIDCProviderDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'OIDC providers',
      link: routes.oidcProviderList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="my-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            {route.params.oidcProviderId}
          </h1>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
          <OIDCProviderDeleteModal
            projectId={route.params.projectId}
            identityProviderId={route.params.oidcProviderId}
          />
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <OIDCProviderInfo
            projectId={route.params.projectId}
            identityProviderId={route.params.oidcProviderId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
