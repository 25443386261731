import YAML from 'yaml';

import { objectResourceName } from '../../helpers/util';
import { useGetAsset } from '../../hooks/use_delivery_api';

interface AssetManifestProps {
  projectId: string;
  appId: string;
  clusterId: string;
  apiGroup: string;
  apiKind: string;
  namespaceName: string;
  objectName: string;
}

export function AssetManifest(props: AssetManifestProps) {
  const name = objectResourceName(
    props.projectId,
    props.appId,
    props.clusterId,
    props.apiGroup,
    props.apiKind,
    props.namespaceName,
    props.objectName,
  );
  const { data } = useGetAsset({ name: name });
  const asset = data;

  return (
    <div className="mt-6 overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="overflow-x-auto whitespace-pre font-mono text-sm text-gray-900">
          <div>
            {YAML.stringify(JSON.parse(asset.payload), {
              indentSeq: false,
            }).trim()}
          </div>
        </div>
      </div>
    </div>
  );
}
