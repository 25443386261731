// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file annealing/compute/v1/enums.proto (package annealing.compute.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum annealing.compute.v1.MachineState
 */
export enum MachineState {
  /**
   * @generated from enum value: MACHINE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MACHINE_STATE_CREATING = 1;
   */
  CREATING = 1,

  /**
   * @generated from enum value: MACHINE_STATE_UPDATING = 2;
   */
  UPDATING = 2,

  /**
   * @generated from enum value: MACHINE_STATE_DELETING = 3;
   */
  DELETING = 3,

  /**
   * @generated from enum value: MACHINE_STATE_ACTIVE = 4;
   */
  ACTIVE = 4,

  /**
   * @generated from enum value: MACHINE_STATE_FAILED = 5;
   */
  FAILED = 5,

  /**
   * @generated from enum value: MACHINE_STATE_DEGRADED = 6;
   */
  DEGRADED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(MachineState)
proto3.util.setEnumType(MachineState, "annealing.compute.v1.MachineState", [
  { no: 0, name: "MACHINE_STATE_UNSPECIFIED" },
  { no: 1, name: "MACHINE_STATE_CREATING" },
  { no: 2, name: "MACHINE_STATE_UPDATING" },
  { no: 3, name: "MACHINE_STATE_DELETING" },
  { no: 4, name: "MACHINE_STATE_ACTIVE" },
  { no: 5, name: "MACHINE_STATE_FAILED" },
  { no: 6, name: "MACHINE_STATE_DEGRADED" },
]);

/**
 * @generated from enum annealing.compute.v1.ClusterState
 */
export enum ClusterState {
  /**
   * @generated from enum value: CLUSTER_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CLUSTER_STATE_CREATING = 1;
   */
  CREATING = 1,

  /**
   * @generated from enum value: CLUSTER_STATE_UPDATING = 2;
   */
  UPDATING = 2,

  /**
   * @generated from enum value: CLUSTER_STATE_DELETING = 3;
   */
  DELETING = 3,

  /**
   * @generated from enum value: CLUSTER_STATE_ACTIVE = 4;
   */
  ACTIVE = 4,

  /**
   * @generated from enum value: CLUSTER_STATE_FAILED = 5;
   */
  FAILED = 5,

  /**
   * @generated from enum value: CLUSTER_STATE_DEGRADED = 6;
   */
  DEGRADED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ClusterState)
proto3.util.setEnumType(ClusterState, "annealing.compute.v1.ClusterState", [
  { no: 0, name: "CLUSTER_STATE_UNSPECIFIED" },
  { no: 1, name: "CLUSTER_STATE_CREATING" },
  { no: 2, name: "CLUSTER_STATE_UPDATING" },
  { no: 3, name: "CLUSTER_STATE_DELETING" },
  { no: 4, name: "CLUSTER_STATE_ACTIVE" },
  { no: 5, name: "CLUSTER_STATE_FAILED" },
  { no: 6, name: "CLUSTER_STATE_DEGRADED" },
]);

