interface DigitalOceanIconProps {
  className: string;
  ariaHidden: string;
}

export function DigitalOceanIcon(props: DigitalOceanIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      fill="currentColor"
      {...props}
    >
      <title>DigitalOcean</title>
      <path d="M12 6C8.68629 6 6 8.68629 6 12H1C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23V18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6Z" />
      <path d="M7 18V13H12V18H7Z" />
      <path d="M3 18V22H7V18H3Z" />
      <path d="M3 18H1V16H3V18Z" />
    </svg>
  );
}
