import { Suspense } from 'react';
import { Route } from 'type-route';

import { routes } from '../../router';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { ProjectInfo } from './project_info';

interface SettingsProps {
  route: Route<typeof routes.projectSettings>;
}

export function Settings(props: SettingsProps) {
  const { route } = props;
  return (
    <div>
      <div>
        <nav className="sm:hidden">
          <a
            href="#"
            className="flex items-center text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700"
          >
            <svg
              className="mr-1 -ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            Back
          </a>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-semibold text-gray-900">
            Project settings
          </h1>
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ProjectInfo projectId={route.params.projectId} />
        </Suspense>
      </ErrorBoundary>
      <div className="mt-8 max-w-xl border border-gray-200 bg-white shadow-sm sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Delete this project
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Once you delete this project, you will lose all data associated
              with it.
            </p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
            >
              Delete project
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
