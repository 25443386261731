import { CubeIcon, TagIcon } from '@heroicons/react/20/solid';

import { clusterResourceName } from '../../helpers/util';
import { useGetCluster } from '../../hooks/use_delivery_api';
import { ClusterDeleteModal } from './cluster_delete_modal';
import { ClusterDistributionBadge } from './cluster_distribution_badge';
import { ClusterStateBadge } from './cluster_state_badge';

interface ClusterHeadingProps {
  projectId: string;
  clusterId: string;
}

export function ClusterHeading(props: ClusterHeadingProps) {
  const name = clusterResourceName(props.projectId, props.clusterId);
  const { data } = useGetCluster({ name: name });
  const cluster = data;

  return (
    <div className="my-2 md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-medium text-gray-900">
          {props.clusterId}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <ClusterStateBadge state={cluster.state} />
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <ClusterDistributionBadge distribution={cluster.distribution} />
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <TagIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {cluster.version === '' ? '\u2014' : cluster.version}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CubeIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {cluster.assetCount}
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
        <div className="space-x-3">
          <ClusterDeleteModal
            projectId={props.projectId}
            clusterId={props.clusterId}
          />
        </div>
      </div>
    </div>
  );
}
