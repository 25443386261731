import {
  ArrowUpCircleIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import { assetSyncStateToString } from '../../helpers/util';

interface AssetSyncStateBadgeProps {
  state: number;
}

export function AssetSyncStateBadge(props: AssetSyncStateBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {assetSyncStateToString(props.state) === 'Synced' && (
        <CheckCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {assetSyncStateToString(props.state) === 'OutOfSync' && (
        <ArrowUpCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
          aria-hidden="true"
        />
      )}
      {assetSyncStateToString(props.state) === 'Unknown' && (
        <QuestionMarkCircleIcon
          className="text-grey-400 mr-1.5 h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
      )}
      {assetSyncStateToString(props.state) === 'Unspecified'
        ? '\u2014'
        : assetSyncStateToString(props.state)}
    </span>
  );
}
