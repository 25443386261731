import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

import { classNames, projectResourceName } from '../../helpers/util';
import {
  useGetProject,
  useListProjects,
} from '../../hooks/use_organization_api';
import { routes } from '../../router';

export interface ProjectMenuProps {
  projectId: string;
}

export function ProjectMenu(props: ProjectMenuProps) {
  const name = projectResourceName(props.projectId);
  const getProjectResult = useGetProject({ name: name });
  const listProjectsResult = useListProjects({ pageSize: 1, pageToken: '' });

  const project = getProjectResult.data;
  const projects = listProjectsResult.data.projects;

  return (
    <Menu as="div" className="relative mt-2 mb-2 inline-block px-2 text-left">
      {({ open }) => (
        <>
          <div className="space-y-1">
            <Menu.Button className="group w-full rounded-md px-2.5 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:border-gray-500 dark:text-white dark:hover:bg-gray-700">
              <span className="flex w-full items-center justify-between">
                <span className="flex min-w-0 items-center justify-between space-x-3">
                  <img
                    className="h-9 w-9 flex-shrink-0 rounded-md bg-gray-300"
                    src={`/avatar?name=${project.displayName}&size=64&fsize=20`}
                    alt="avatar"
                  />
                  <span className="flex min-w-0 flex-1 flex-col">
                    <span className="truncate text-xs text-left text-gray-400">
                      Project
                    </span>
                    <span className="truncate text-sm text-left text-gray-900 dark:text-white">
                      {project.displayName}
                    </span>
                  </span>
                </span>
                <ChevronDownIcon
                  className="h-5 w-5 ml-3 flex-shrink-0 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute left-0 right-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-500 dark:bg-gray-800 dark:ring-gray-500"
            >
              <div className="px-4 py-1">
                <p className="truncate py-2 text-sm font-medium text-gray-400">
                  Choose a project
                </p>
                {projects.map((project) => (
                  <Menu.Item key={project.name}>
                    {({ active }) => (
                      <a
                        {...routes.projectDetail({
                          projectId: project.name.split('/')[1],
                        }).link}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white'
                            : 'text-gray-700 dark:text-gray-400',
                          'relative block px-4 py-2 text-sm',
                        )}
                      >
                        <span className="block truncate">
                          {project.displayName}
                        </span>
                        {project.name.split('/')[1] == props.projectId ?
                          (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true" />
                            </span>
                          ) :
                          null}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      {...routes.projectCreate().link}
                      className={classNames(
                        active
                          ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white'
                          : 'text-gray-700 dark:text-gray-400',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      New project
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
