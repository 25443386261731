import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { ClusterHeading } from './cluster_heading';

interface ClusterDetailProps {
  route: Route<typeof routes.computeClusterDetail>;
}

export function ClusterDetail(props: ClusterDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Clusters',
      link: routes.computeClusterList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ClusterHeading
            projectId={route.params.projectId}
            clusterId={route.params.clusterId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
