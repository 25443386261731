import { projectResourceName } from '../../helpers/util';
import { useGetProject } from '../../hooks/use_organization_api';

interface ProjectInfoProps {
  projectId: string;
}

export function ProjectInfo(props: ProjectInfoProps) {
  const name = projectResourceName(props.projectId);
  const { data } = useGetProject({ name: name });
  const project = data;

  return (
    <div className="mt-4 max-w-xl overflow-hidden border-t border-b border-gray-200">
      <div className="px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Display name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {project.displayName}
            </dd>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Project ID</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {project.name.split('/')[1]}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
