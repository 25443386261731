import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { Controller } from 'react-hook-form';

import { classNames } from '../../helpers/util';
import { useListMachines } from '../../hooks/use_compute_api';

interface MachineChooserProps {
  projectId: string;
  control: any;
}

export function MachineChooser(props: MachineChooserProps) {
  const { data } = useListMachines({
    parent: `projects/${props.projectId}`,
    pageSize: 15,
    pageToken: '',
  });
  const machines = data.machines;
  const [selectedMachines, setSelectedMachines] = useState([]);

  return (
    <Controller
      name="machines"
      control={props.control}
      rules={{ required: true }}
      render={({ field: { onChange } }) => (
        <Listbox
          as="div"
          className="space-y-1"
          value={selectedMachines}
          onChange={(e) => {
            onChange(e);
            setSelectedMachines(e);
          }}
          multiple
        >
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-700">
                Machines
              </Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <span className="block truncate">
                    {selectedMachines.length > 0
                      ? selectedMachines.map((machine) => machine.split('/')[3])
                        .sort().join(', ')
                      : 'No machines selected'}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {machines.map((machine) => (
                      <Listbox.Option
                        key={machine.name}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-8 pr-4',
                          )}
                        value={machine.name}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              )}>
                              {machine.name.split('/')[3]}
                            </span>
                            {selected ?
                              (
                                <span
                                  className={classNames(
                                    active
                                      ? 'text-white'
                                      : 'text-indigo-600',
                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true" />
                                </span>
                              ) :
                              null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
    />
  );
}
