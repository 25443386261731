import { TrashIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { oidcProviderResourceName } from '../../helpers/util';
import { deleteIdentityProvider } from '../../hooks/use_iam_api';
import { routes } from '../../router';
import { DeleteModal } from '../core/delete_modal';
import { createToast } from '../core/toast';

interface OIDCProviderDeleteModalProps {
  projectId: string;
  identityProviderId: string;
}

export function OIDCProviderDeleteModal(props: OIDCProviderDeleteModalProps) {
  const [open, setOpen] = useState(false);

  const onSubmit = async () => {
    try {
      await deleteIdentityProvider({
        name: oidcProviderResourceName(
          props.projectId,
          props.identityProviderId,
        ),
      });
      createToast(`OIDC provider "${props.identityProviderId}" deleted.`);
      routes.oidcProviderList({
        projectId: props.projectId,
      }).push();
    } catch (err) {
      createToast(`Error deleting OIDC provider: ${err}`);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <TrashIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
        Delete
      </button>
      <DeleteModal
        title="Delete identity provider"
        description={`Are you sure you want to delete ${props.identityProviderId}? This action cannot be undone.`}
        action="Delete"
        onSubmit={onSubmit}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
