import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';
import { machineStateToString } from '../../helpers/util';
import { CircularProgress } from '../core/circular_progress';

interface MachineStateBadgeProps {
  state: number;
}

export function MachineStateBadge(props: MachineStateBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {machineStateToString(props.state) == 'Creating' && (
        <CircularProgress className="mr-2 ml-0.5 h-4 w-4 animate-spin text-blue-500" />
      )}
      {machineStateToString(props.state) == 'Updating' && (
        <CircularProgress className="mr-2 ml-0.5 h-4 w-4 animate-spin text-blue-500" />
      )}
      {machineStateToString(props.state) == 'Deleting' && (
        <CircularProgress className="mr-2 ml-0.5 h-4 w-4 animate-spin text-blue-500" />
      )}
      {machineStateToString(props.state) === 'Active' && (
        <CheckCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {machineStateToString(props.state) === 'Failed' && (
        <ExclamationCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
          aria-hidden="true"
        />
      )}
      {machineStateToString(props.state)}
    </span>
  );
}
