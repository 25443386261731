import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';

import { projectResourceName } from '../../helpers/util';
import { createMachine } from '../../hooks/use_compute_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { createToast } from '../core/toast';

interface MachineCreateProps {
  route: Route<typeof routes.computeMachineCreate>;
}

interface MachineCreateFormData {
  name: string;
}

export function MachineCreate(props: MachineCreateProps) {
  const { route } = props;
  const { register, handleSubmit, formState } = useForm<
    MachineCreateFormData
  >();

  const parent = projectResourceName(route.params.projectId);

  const { isSubmitting } = formState;

  const onSubmit = async (data: MachineCreateFormData) => {
    try {
      await createMachine({
        parent: parent,
        machineId: data.name,
        validateOnly: false,
      });
      createToast(`Machine "${data.name}" created.`);
      routes.computeMachineDetail({
        projectId: route.params.projectId,
        machineId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating machine: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'Machines',
      link: routes.computeMachineList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">New machine</h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        A machine can be attached to your project for remote management.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Machine name
          </label>
          <div className="mt-1">
            <input
              {...register('name')}
              type="text"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.computeMachineList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className={`ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                isSubmitting
                  ? 'cursor-not-allowed opacity-50'
                  : 'hover:bg-indigo-700'
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Attaching...' : 'Attach'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
