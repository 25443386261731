import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';
import { z } from 'zod';

import { projectResourceName, roleToProto } from '../../helpers/util';
import { createRoleBinding } from '../../hooks/use_iam_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { createToast } from '../core/toast';

interface RoleBindingCreateProps {
  route: Route<typeof routes.roleBindingCreate>;
}

interface RoleBindingCreateFormData {
  name: string;
  member: string;
  role: string;
}

const letterStartRegExp = new RegExp('^[a-z]');
const letterNumberEndRegExp = new RegExp('[a-z0-9]$');
const nameRegExp = new RegExp('^[-a-z0-9]*$');
const memberRegExp = new RegExp('^[a-z]([-a-z0-9@:/-]*[a-z0-9])?$');

export function RoleBindingCreate(props: RoleBindingCreateProps) {
  const { route } = props;
  const roles = ['Owner', 'Editor', 'Viewer', 'Agent'] as const;
  const schema = z.object({
    name: z
      .string()
      .min(1, 'Name is required')
      .max(63, 'Name may be at most 63 characters')
      .regex(nameRegExp, {
        message:
          'Name must consist of lowercase letters (a-z), numbers, and hyphens',
      })
      .regex(letterStartRegExp, {
        message: 'Name must start with a lowercase letter',
      })
      .regex(letterNumberEndRegExp, {
        message: 'Name must end with a lowercase letter or a number',
      }),
    member: z.string().regex(memberRegExp, {
      message:
        'Member must consist of letters, numbers, hyphens, colons, slashes, and @',
    }),
    role: z.enum(roles),
  });
  const { register, handleSubmit, formState } = useForm<
    RoleBindingCreateFormData
  >({
    resolver: zodResolver(schema),
  });
  const parent = projectResourceName(route.params.projectId);

  const { isSubmitting, errors } = formState;

  const onSubmit = async (data: RoleBindingCreateFormData) => {
    try {
      await createRoleBinding({
        parent: parent,
        roleBinding: {
          name: '',
          state: 0,
          member: data.member,
          role: roleToProto(data.role),
        },
        roleBindingId: data.name,
      });
      createToast(`Role binding "${data.name}" created.`);
      routes.roleBindingDetail({
        projectId: route.params.projectId,
        roleBindingId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating role binding: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'Role bindings',
      link: routes.roleBindingList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            New role binding
          </h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        A role binding grants permissions to a member to access this project.
        For example, you can create a role binding to grant{' '}
        <span className="italic">person@example.com</span>{' '}
        access to this project using the role{' '}
        <span className="italic">Viewer</span>.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Role binding name
              </label>
              <div
                className={`mt-1 ${
                  errors.name ? 'relative rounded-md shadow-sm' : ''
                }`}
              >
                <input
                  {...register('name')}
                  id="name"
                  type="text"
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors.name
                      ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                      : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  }`}
                  aria-invalid={!!errors.name}
                />
                {errors.name && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p className="mt-2 text-sm text-red-600" id="name-error">
                {errors.name?.message}
              </p>
            </div>
            <div>
              <label
                htmlFor="member"
                className="block text-sm font-medium text-gray-700"
              >
                Member
              </label>
              <div
                className={`mt-1 ${
                  errors.member ? 'relative rounded-md shadow-sm' : ''
                }`}
              >
                <input
                  {...register('member')}
                  id="member"
                  type="text"
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors.member
                      ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                      : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  }`}
                  aria-invalid={!!errors.member}
                />
                {errors.member && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p className="mt-2 text-sm text-red-600" id="member-error">
                {errors.member?.message}
              </p>
            </div>
            <div>
              <label
                htmlFor="role"
                className="block text-sm font-medium text-gray-700"
              >
                Role
              </label>
              <div className="mt-1">
                <select
                  {...register('role')}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  {roles.map((role) => <option>{role}</option>)}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.roleBindingList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className={`ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                isSubmitting
                  ? 'cursor-not-allowed opacity-50'
                  : 'hover:bg-indigo-700'
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Creating...' : 'Create'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
