import { Suspense } from 'react';
import { Route } from 'type-route';

import { routes } from '../../router';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { ProjectStats } from './project_stats';

interface DashboardProps {
  route: Route<typeof routes.projectDetail>;
}

export function Dashboard(props: DashboardProps) {
  const { route } = props;
  return (
    <div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ProjectStats projectId={route.params.projectId} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
