import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { MachineHeading } from './machine_heading';

interface MachineDetailProps {
  route: Route<typeof routes.computeMachineDetail>;
}

export function MachineDetail(props: MachineDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Machines',
      link: routes.computeMachineList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <MachineHeading
            projectId={route.params.projectId}
            machineId={route.params.machineId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
