// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file annealing/delivery/v1/service.proto (package annealing.delivery.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CancelRolloutRequest, CancelRolloutResponse, CreateApplicationRequest, CreateChartRepositoryRequest, CreateClusterRequest, CreateGitRepositoryRequest, CreateIgnoreDifferenceRequest, CreateSshKeyRequest, CreateSshKnownHostRequest, DeleteApplicationRequest, DeleteChartRepositoryRequest, DeleteClusterRequest, DeleteGitRepositoryRequest, DeleteIgnoreDifferenceRequest, DeleteSshKeyRequest, DeleteSshKnownHostRequest, GetApplicationRequest, GetAssetRequest, GetChartRepositoryRequest, GetClusterRequest, GetGitRepositoryRequest, GetIgnoreDifferenceRequest, GetIncarnationAssetRequest, GetIncarnationFileRequest, GetIncarnationRequest, GetRolloutAssetRequest, GetRolloutRequest, GetRolloutStepRequest, GetSshKeyRequest, GetSshKnownHostRequest, ListApplicationsRequest, ListApplicationsResponse, ListAssetsRequest, ListAssetsResponse, ListChartRepositoriesRequest, ListChartRepositoriesResponse, ListChartsRequest, ListChartsResponse, ListClustersRequest, ListClustersResponse, ListGitRepositoriesRequest, ListGitRepositoriesResponse, ListIgnoreDifferencesRequest, ListIgnoreDifferencesResponse, ListIncarnationAssetsRequest, ListIncarnationAssetsResponse, ListIncarnationFilesRequest, ListIncarnationFilesResponse, ListIncarnationsRequest, ListIncarnationsResponse, ListRolloutAssetsRequest, ListRolloutAssetsResponse, ListRolloutsRequest, ListRolloutsResponse, ListRolloutStepsRequest, ListRolloutStepsResponse, ListSshKeysRequest, ListSshKeysResponse, ListSshKnownHostsRequest, ListSshKnownHostsResponse, PauseRolloutRequest, PauseRolloutResponse, ResumeRolloutRequest, ResumeRolloutResponse, SearchRolloutsRequest, SearchRolloutsResponse, UpdateApplicationRequest } from "./service_pb.js";
import { Application, Asset, ChartRepository, Cluster, GitRepository, IgnoreDifference, Incarnation, IncarnationAsset, IncarnationFile, Rollout, RolloutAsset, RolloutStep, SshKey, SshKnownHost } from "./resources_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service annealing.delivery.v1.DeliveryService
 */
export const DeliveryService = {
  typeName: "annealing.delivery.v1.DeliveryService",
  methods: {
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetSshKey
     */
    getSshKey: {
      name: "GetSshKey",
      I: GetSshKeyRequest,
      O: SshKey,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListSshKeys
     */
    listSshKeys: {
      name: "ListSshKeys",
      I: ListSshKeysRequest,
      O: ListSshKeysResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CreateSshKey
     */
    createSshKey: {
      name: "CreateSshKey",
      I: CreateSshKeyRequest,
      O: SshKey,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.DeleteSshKey
     */
    deleteSshKey: {
      name: "DeleteSshKey",
      I: DeleteSshKeyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetSshKnownHost
     */
    getSshKnownHost: {
      name: "GetSshKnownHost",
      I: GetSshKnownHostRequest,
      O: SshKnownHost,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListSshKnownHosts
     */
    listSshKnownHosts: {
      name: "ListSshKnownHosts",
      I: ListSshKnownHostsRequest,
      O: ListSshKnownHostsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CreateSshKnownHost
     */
    createSshKnownHost: {
      name: "CreateSshKnownHost",
      I: CreateSshKnownHostRequest,
      O: SshKnownHost,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.DeleteSshKnownHost
     */
    deleteSshKnownHost: {
      name: "DeleteSshKnownHost",
      I: DeleteSshKnownHostRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetIgnoreDifference
     */
    getIgnoreDifference: {
      name: "GetIgnoreDifference",
      I: GetIgnoreDifferenceRequest,
      O: IgnoreDifference,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListIgnoreDifferences
     */
    listIgnoreDifferences: {
      name: "ListIgnoreDifferences",
      I: ListIgnoreDifferencesRequest,
      O: ListIgnoreDifferencesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CreateIgnoreDifference
     */
    createIgnoreDifference: {
      name: "CreateIgnoreDifference",
      I: CreateIgnoreDifferenceRequest,
      O: IgnoreDifference,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.DeleteIgnoreDifference
     */
    deleteIgnoreDifference: {
      name: "DeleteIgnoreDifference",
      I: DeleteIgnoreDifferenceRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetCluster
     */
    getCluster: {
      name: "GetCluster",
      I: GetClusterRequest,
      O: Cluster,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListClusters
     */
    listClusters: {
      name: "ListClusters",
      I: ListClustersRequest,
      O: ListClustersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CreateCluster
     */
    createCluster: {
      name: "CreateCluster",
      I: CreateClusterRequest,
      O: Cluster,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.DeleteCluster
     */
    deleteCluster: {
      name: "DeleteCluster",
      I: DeleteClusterRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetApplication
     */
    getApplication: {
      name: "GetApplication",
      I: GetApplicationRequest,
      O: Application,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListApplications
     */
    listApplications: {
      name: "ListApplications",
      I: ListApplicationsRequest,
      O: ListApplicationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CreateApplication
     */
    createApplication: {
      name: "CreateApplication",
      I: CreateApplicationRequest,
      O: Application,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.UpdateApplication
     */
    updateApplication: {
      name: "UpdateApplication",
      I: UpdateApplicationRequest,
      O: Application,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.DeleteApplication
     */
    deleteApplication: {
      name: "DeleteApplication",
      I: DeleteApplicationRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetIncarnation
     */
    getIncarnation: {
      name: "GetIncarnation",
      I: GetIncarnationRequest,
      O: Incarnation,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListIncarnations
     */
    listIncarnations: {
      name: "ListIncarnations",
      I: ListIncarnationsRequest,
      O: ListIncarnationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetIncarnationFile
     */
    getIncarnationFile: {
      name: "GetIncarnationFile",
      I: GetIncarnationFileRequest,
      O: IncarnationFile,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListIncarnationFiles
     */
    listIncarnationFiles: {
      name: "ListIncarnationFiles",
      I: ListIncarnationFilesRequest,
      O: ListIncarnationFilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetIncarnationAsset
     */
    getIncarnationAsset: {
      name: "GetIncarnationAsset",
      I: GetIncarnationAssetRequest,
      O: IncarnationAsset,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListIncarnationAssets
     */
    listIncarnationAssets: {
      name: "ListIncarnationAssets",
      I: ListIncarnationAssetsRequest,
      O: ListIncarnationAssetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetAsset
     */
    getAsset: {
      name: "GetAsset",
      I: GetAssetRequest,
      O: Asset,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListAssets
     */
    listAssets: {
      name: "ListAssets",
      I: ListAssetsRequest,
      O: ListAssetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetRollout
     */
    getRollout: {
      name: "GetRollout",
      I: GetRolloutRequest,
      O: Rollout,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListRollouts
     */
    listRollouts: {
      name: "ListRollouts",
      I: ListRolloutsRequest,
      O: ListRolloutsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.SearchRollouts
     */
    searchRollouts: {
      name: "SearchRollouts",
      I: SearchRolloutsRequest,
      O: SearchRolloutsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.PauseRollout
     */
    pauseRollout: {
      name: "PauseRollout",
      I: PauseRolloutRequest,
      O: PauseRolloutResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ResumeRollout
     */
    resumeRollout: {
      name: "ResumeRollout",
      I: ResumeRolloutRequest,
      O: ResumeRolloutResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CancelRollout
     */
    cancelRollout: {
      name: "CancelRollout",
      I: CancelRolloutRequest,
      O: CancelRolloutResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetRolloutStep
     */
    getRolloutStep: {
      name: "GetRolloutStep",
      I: GetRolloutStepRequest,
      O: RolloutStep,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListRolloutSteps
     */
    listRolloutSteps: {
      name: "ListRolloutSteps",
      I: ListRolloutStepsRequest,
      O: ListRolloutStepsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetRolloutAsset
     */
    getRolloutAsset: {
      name: "GetRolloutAsset",
      I: GetRolloutAssetRequest,
      O: RolloutAsset,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListRolloutAssets
     */
    listRolloutAssets: {
      name: "ListRolloutAssets",
      I: ListRolloutAssetsRequest,
      O: ListRolloutAssetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetGitRepository
     */
    getGitRepository: {
      name: "GetGitRepository",
      I: GetGitRepositoryRequest,
      O: GitRepository,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListGitRepositories
     */
    listGitRepositories: {
      name: "ListGitRepositories",
      I: ListGitRepositoriesRequest,
      O: ListGitRepositoriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CreateGitRepository
     */
    createGitRepository: {
      name: "CreateGitRepository",
      I: CreateGitRepositoryRequest,
      O: GitRepository,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.DeleteGitRepository
     */
    deleteGitRepository: {
      name: "DeleteGitRepository",
      I: DeleteGitRepositoryRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.GetChartRepository
     */
    getChartRepository: {
      name: "GetChartRepository",
      I: GetChartRepositoryRequest,
      O: ChartRepository,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListChartRepositories
     */
    listChartRepositories: {
      name: "ListChartRepositories",
      I: ListChartRepositoriesRequest,
      O: ListChartRepositoriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.CreateChartRepository
     */
    createChartRepository: {
      name: "CreateChartRepository",
      I: CreateChartRepositoryRequest,
      O: ChartRepository,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.DeleteChartRepository
     */
    deleteChartRepository: {
      name: "DeleteChartRepository",
      I: DeleteChartRepositoryRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.delivery.v1.DeliveryService.ListCharts
     */
    listCharts: {
      name: "ListCharts",
      I: ListChartsRequest,
      O: ListChartsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

