// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file annealing/compute/v1/service.proto (package annealing.compute.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateClusterRequest, CreateMachineRequest, DeleteClusterRequest, DeleteMachineRequest, GetClusterRequest, GetMachineRequest, ListClustersRequest, ListClustersResponse, ListMachinesRequest, ListMachinesResponse } from "./service_pb.js";
import { Cluster, Machine } from "./resources_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { Operation } from "../../../google/longrunning/operations_pb.js";

/**
 * @generated from service annealing.compute.v1.ComputeService
 */
export const ComputeService = {
  typeName: "annealing.compute.v1.ComputeService",
  methods: {
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.GetMachine
     */
    getMachine: {
      name: "GetMachine",
      I: GetMachineRequest,
      O: Machine,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.ListMachines
     */
    listMachines: {
      name: "ListMachines",
      I: ListMachinesRequest,
      O: ListMachinesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.CreateMachine
     */
    createMachine: {
      name: "CreateMachine",
      I: CreateMachineRequest,
      O: Operation,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.DeleteMachine
     */
    deleteMachine: {
      name: "DeleteMachine",
      I: DeleteMachineRequest,
      O: Operation,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.GetCluster
     */
    getCluster: {
      name: "GetCluster",
      I: GetClusterRequest,
      O: Cluster,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.ListClusters
     */
    listClusters: {
      name: "ListClusters",
      I: ListClustersRequest,
      O: ListClustersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.CreateCluster
     */
    createCluster: {
      name: "CreateCluster",
      I: CreateClusterRequest,
      O: Operation,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.compute.v1.ComputeService.DeleteCluster
     */
    deleteCluster: {
      name: "DeleteCluster",
      I: DeleteClusterRequest,
      O: Operation,
      kind: MethodKind.Unary,
    },
  }
} as const;

