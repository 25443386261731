import { useForm } from 'react-hook-form';
import { Route } from 'type-route';

import { oidcAccessTypeToProto, projectResourceName } from '../../helpers/util';
import { createIdentityProvider } from '../../hooks/use_iam_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { createToast } from '../core/toast';

interface OIDCProviderCreateProps {
  route: Route<typeof routes.oidcProviderCreate>;
}

interface OIDCProviderCreateFormData {
  name: string;
  oidcIssuerUri: string;
  oidcAccessType: string;
  oidcJwks: string;
}

export function OIDCProviderCreate(props: OIDCProviderCreateProps) {
  const { route } = props;
  const { register, watch, handleSubmit } = useForm<
    OIDCProviderCreateFormData
  >();

  const parent = projectResourceName(route.params.projectId);

  const onSubmit = async (data: OIDCProviderCreateFormData) => {
    try {
      await createIdentityProvider({
        parent: parent,
        identityProvider: {
          name: '',
          state: 0,
          oidc: {
            issuerUri: data.oidcIssuerUri,
            accessType: oidcAccessTypeToProto(data.oidcAccessType),
            jwks: data.oidcJwks,
          },
        },
        identityProviderId: data.name,
      });
      createToast(`OIDC provider "${data.name}" created.`);
      routes.oidcProviderDetail({
        projectId: route.params.projectId,
        oidcProviderId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating OIDC provider: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'OIDC providers',
      link: routes.oidcProviderList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  const oidcAccessTypes = [
    {
      id: 'public',
      name: 'Public',
      description:
        'The OIDC issuer and JWKS URLs are accessible to the public Internet',
    },
    {
      id: 'private',
      name: 'Private',
      description:
        'The OIDC issuer and JWKS URLs are inaccessible behind a firewall',
    },
  ];

  const oidcAccessType = watch('oidcAccessType');

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            New OIDC provider
          </h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        An identity provider (IdP) is a system that manages identity information
        for principals and provides authentication services to relying
        applications.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  {...register('name')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="oidcIssuerUri"
                className="block text-sm font-medium text-gray-700"
              >
                Issuer URL
              </label>
              <div className="mt-1">
                <input
                  {...register('oidcIssuerUri')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Discovery type
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                This defines how the JSON Web Key Set (JWKS) for the identity
                provider will be accessed.
              </p>
            </div>
            <fieldset className="mt-4">
              <legend className="sr-only">OIDC discovery type</legend>
              <div className="space-y-5">
                {oidcAccessTypes.map((v) => (
                  <div key={v.id} className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        {...register('oidcAccessType')}
                        id={v.id}
                        aria-describedby={`${v.id}-description`}
                        type="radio"
                        defaultChecked={v.id === 'public'}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        value={v.name}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={v.id}
                        className="font-medium text-gray-700"
                      >
                        {v.name}
                      </label>
                      <p id={`${v.id}-description`} className="text-gray-500">
                        {v.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          {oidcAccessType === 'Private' && (
            <div className="ml-7">
              <div className="text-sm">
                <label
                  htmlFor="oidcJwks"
                  className="block text-sm font-medium text-gray-700"
                >
                  JSON Web Key Set (JWKS)
                </label>
              </div>
              <div className="mt-1">
                <textarea
                  rows={6}
                  {...register('oidcJwks')}
                  id="oidcJwks"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  defaultValue={''}
                />
              </div>
            </div>
          )}
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.oidcProviderList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="ml-3 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
