// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file annealing/iam/v1/enums.proto (package annealing.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum annealing.iam.v1.IdentityProviderState
 */
export enum IdentityProviderState {
  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_ERROR = 3;
   */
  ERROR = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(IdentityProviderState)
proto3.util.setEnumType(IdentityProviderState, "annealing.iam.v1.IdentityProviderState", [
  { no: 0, name: "IDENTITY_PROVIDER_STATE_UNSPECIFIED" },
  { no: 1, name: "IDENTITY_PROVIDER_STATE_PENDING" },
  { no: 2, name: "IDENTITY_PROVIDER_STATE_ACTIVE" },
  { no: 3, name: "IDENTITY_PROVIDER_STATE_ERROR" },
]);

/**
 * @generated from enum annealing.iam.v1.OidcAccessType
 */
export enum OidcAccessType {
  /**
   * @generated from enum value: OIDC_ACCESS_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: OIDC_ACCESS_TYPE_PUBLIC = 1;
   */
  PUBLIC = 1,

  /**
   * @generated from enum value: OIDC_ACCESS_TYPE_PRIVATE = 2;
   */
  PRIVATE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(OidcAccessType)
proto3.util.setEnumType(OidcAccessType, "annealing.iam.v1.OidcAccessType", [
  { no: 0, name: "OIDC_ACCESS_TYPE_UNSPECIFIED" },
  { no: 1, name: "OIDC_ACCESS_TYPE_PUBLIC" },
  { no: 2, name: "OIDC_ACCESS_TYPE_PRIVATE" },
]);

/**
 * @generated from enum annealing.iam.v1.Role
 */
export enum Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLE_OWNER = 1;
   */
  OWNER = 1,

  /**
   * @generated from enum value: ROLE_EDITOR = 2;
   */
  EDITOR = 2,

  /**
   * @generated from enum value: ROLE_VIEWER = 3;
   */
  VIEWER = 3,

  /**
   * @generated from enum value: ROLE_AGENT = 4;
   */
  AGENT = 4,

  /**
   * @generated from enum value: ROLE_DEPLOYER = 5;
   */
  DEPLOYER = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Role)
proto3.util.setEnumType(Role, "annealing.iam.v1.Role", [
  { no: 0, name: "ROLE_UNSPECIFIED" },
  { no: 1, name: "ROLE_OWNER" },
  { no: 2, name: "ROLE_EDITOR" },
  { no: 3, name: "ROLE_VIEWER" },
  { no: 4, name: "ROLE_AGENT" },
  { no: 5, name: "ROLE_DEPLOYER" },
]);

/**
 * @generated from enum annealing.iam.v1.RoleBindingState
 */
export enum RoleBindingState {
  /**
   * @generated from enum value: ROLE_BINDING_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLE_BINDING_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: ROLE_BINDING_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(RoleBindingState)
proto3.util.setEnumType(RoleBindingState, "annealing.iam.v1.RoleBindingState", [
  { no: 0, name: "ROLE_BINDING_STATE_UNSPECIFIED" },
  { no: 1, name: "ROLE_BINDING_STATE_PENDING" },
  { no: 2, name: "ROLE_BINDING_STATE_ACTIVE" },
]);

/**
 * @generated from enum annealing.iam.v1.ServiceAccountState
 */
export enum ServiceAccountState {
  /**
   * @generated from enum value: SERVICE_ACCOUNT_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ServiceAccountState)
proto3.util.setEnumType(ServiceAccountState, "annealing.iam.v1.ServiceAccountState", [
  { no: 0, name: "SERVICE_ACCOUNT_STATE_UNSPECIFIED" },
  { no: 1, name: "SERVICE_ACCOUNT_STATE_PENDING" },
  { no: 2, name: "SERVICE_ACCOUNT_STATE_ACTIVE" },
]);

/**
 * @generated from enum annealing.iam.v1.ServiceAccountKeyState
 */
export enum ServiceAccountKeyState {
  /**
   * @generated from enum value: SERVICE_ACCOUNT_KEY_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_KEY_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_KEY_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ServiceAccountKeyState)
proto3.util.setEnumType(ServiceAccountKeyState, "annealing.iam.v1.ServiceAccountKeyState", [
  { no: 0, name: "SERVICE_ACCOUNT_KEY_STATE_UNSPECIFIED" },
  { no: 1, name: "SERVICE_ACCOUNT_KEY_STATE_PENDING" },
  { no: 2, name: "SERVICE_ACCOUNT_KEY_STATE_ACTIVE" },
]);

