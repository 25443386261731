// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file annealing/compute/v1/service.proto (package annealing.compute.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Cluster, Machine } from "./resources_pb.js";

/**
 * @generated from message annealing.compute.v1.GetMachineRequest
 */
export class GetMachineRequest extends Message<GetMachineRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetMachineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.GetMachineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMachineRequest {
    return new GetMachineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMachineRequest {
    return new GetMachineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMachineRequest {
    return new GetMachineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMachineRequest | PlainMessage<GetMachineRequest> | undefined, b: GetMachineRequest | PlainMessage<GetMachineRequest> | undefined): boolean {
    return proto3.util.equals(GetMachineRequest, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ListMachinesRequest
 */
export class ListMachinesRequest extends Message<ListMachinesRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListMachinesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ListMachinesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMachinesRequest {
    return new ListMachinesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMachinesRequest {
    return new ListMachinesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMachinesRequest {
    return new ListMachinesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMachinesRequest | PlainMessage<ListMachinesRequest> | undefined, b: ListMachinesRequest | PlainMessage<ListMachinesRequest> | undefined): boolean {
    return proto3.util.equals(ListMachinesRequest, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ListMachinesResponse
 */
export class ListMachinesResponse extends Message<ListMachinesResponse> {
  /**
   * @generated from field: repeated annealing.compute.v1.Machine machines = 1;
   */
  machines: Machine[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListMachinesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ListMachinesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "machines", kind: "message", T: Machine, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMachinesResponse {
    return new ListMachinesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMachinesResponse {
    return new ListMachinesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMachinesResponse {
    return new ListMachinesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMachinesResponse | PlainMessage<ListMachinesResponse> | undefined, b: ListMachinesResponse | PlainMessage<ListMachinesResponse> | undefined): boolean {
    return proto3.util.equals(ListMachinesResponse, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.CreateMachineRequest
 */
export class CreateMachineRequest extends Message<CreateMachineRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.compute.v1.Machine machine = 2;
   */
  machine?: Machine;

  /**
   * @generated from field: string machine_id = 3;
   */
  machineId = "";

  /**
   * @generated from field: bool validate_only = 4;
   */
  validateOnly = false;

  constructor(data?: PartialMessage<CreateMachineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.CreateMachineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "machine", kind: "message", T: Machine },
    { no: 3, name: "machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "validate_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMachineRequest {
    return new CreateMachineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMachineRequest {
    return new CreateMachineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMachineRequest {
    return new CreateMachineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMachineRequest | PlainMessage<CreateMachineRequest> | undefined, b: CreateMachineRequest | PlainMessage<CreateMachineRequest> | undefined): boolean {
    return proto3.util.equals(CreateMachineRequest, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.DeleteMachineRequest
 */
export class DeleteMachineRequest extends Message<DeleteMachineRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: bool validate_only = 2;
   */
  validateOnly = false;

  /**
   * @generated from field: bool allow_missing = 3;
   */
  allowMissing = false;

  /**
   * @generated from field: string etag = 4;
   */
  etag = "";

  constructor(data?: PartialMessage<DeleteMachineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.DeleteMachineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "validate_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "etag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteMachineRequest {
    return new DeleteMachineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteMachineRequest {
    return new DeleteMachineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteMachineRequest {
    return new DeleteMachineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteMachineRequest | PlainMessage<DeleteMachineRequest> | undefined, b: DeleteMachineRequest | PlainMessage<DeleteMachineRequest> | undefined): boolean {
    return proto3.util.equals(DeleteMachineRequest, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.GetClusterRequest
 */
export class GetClusterRequest extends Message<GetClusterRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetClusterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.GetClusterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetClusterRequest | PlainMessage<GetClusterRequest> | undefined, b: GetClusterRequest | PlainMessage<GetClusterRequest> | undefined): boolean {
    return proto3.util.equals(GetClusterRequest, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ListClustersRequest
 */
export class ListClustersRequest extends Message<ListClustersRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListClustersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ListClustersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersRequest | PlainMessage<ListClustersRequest> | undefined, b: ListClustersRequest | PlainMessage<ListClustersRequest> | undefined): boolean {
    return proto3.util.equals(ListClustersRequest, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ListClustersResponse
 */
export class ListClustersResponse extends Message<ListClustersResponse> {
  /**
   * @generated from field: repeated annealing.compute.v1.Cluster clusters = 1;
   */
  clusters: Cluster[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListClustersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ListClustersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "clusters", kind: "message", T: Cluster, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersResponse | PlainMessage<ListClustersResponse> | undefined, b: ListClustersResponse | PlainMessage<ListClustersResponse> | undefined): boolean {
    return proto3.util.equals(ListClustersResponse, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.CreateClusterRequest
 */
export class CreateClusterRequest extends Message<CreateClusterRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.compute.v1.Cluster cluster = 2;
   */
  cluster?: Cluster;

  /**
   * @generated from field: string cluster_id = 3;
   */
  clusterId = "";

  /**
   * @generated from field: bool validate_only = 4;
   */
  validateOnly = false;

  constructor(data?: PartialMessage<CreateClusterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.CreateClusterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cluster", kind: "message", T: Cluster },
    { no: 3, name: "cluster_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "validate_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateClusterRequest {
    return new CreateClusterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateClusterRequest {
    return new CreateClusterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateClusterRequest {
    return new CreateClusterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateClusterRequest | PlainMessage<CreateClusterRequest> | undefined, b: CreateClusterRequest | PlainMessage<CreateClusterRequest> | undefined): boolean {
    return proto3.util.equals(CreateClusterRequest, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.DeleteClusterRequest
 */
export class DeleteClusterRequest extends Message<DeleteClusterRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: bool validate_only = 2;
   */
  validateOnly = false;

  /**
   * @generated from field: bool allow_missing = 3;
   */
  allowMissing = false;

  /**
   * @generated from field: string etag = 4;
   */
  etag = "";

  constructor(data?: PartialMessage<DeleteClusterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.DeleteClusterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "validate_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "etag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteClusterRequest {
    return new DeleteClusterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteClusterRequest {
    return new DeleteClusterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteClusterRequest {
    return new DeleteClusterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteClusterRequest | PlainMessage<DeleteClusterRequest> | undefined, b: DeleteClusterRequest | PlainMessage<DeleteClusterRequest> | undefined): boolean {
    return proto3.util.equals(DeleteClusterRequest, a, b);
  }
}

