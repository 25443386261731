import { formatDate, projectResourceName } from '../../helpers/util';
import { useListSSHKnownHosts } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { SSHKnownHostKebab } from './ssh_known_host_kebab';
import { SSHKnownHostStateBadge } from './ssh_known_host_state_badge';

interface SSHKnownHostTableProps {
  projectId: string;
}

export function SSHKnownHostTable(props: SSHKnownHostTableProps) {
  const parent = projectResourceName(props.projectId);
  const { data, mutate } = useListSSHKnownHosts({
    parent: parent,
    pageSize: 15,
    pageToken: '',
  });
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Hostname
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Algorithm
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Fingerprint
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.sshKnownHosts.length == 0 && (
                <tr>
                  <td
                    colSpan={6}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No SSH known hosts to display
                  </td>
                </tr>
              )}
              {data.sshKnownHosts.map((host) => (
                <tr key={host.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.sshKnownHostDetail({
                        projectId: props.projectId,
                        sshKnownHostId: host.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {host.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <SSHKnownHostStateBadge state={host.state} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {host.hostname}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {host.algorithm}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {host.fingerprintSha256}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {formatDate(host.createTime.toDate())}
                  </td>
                  <td className="w-full border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <SSHKnownHostKebab
                      projectId={props.projectId}
                      hostId={host.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.sshKnownHosts.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.sshKnownHosts.length}
              startIndex={data.startIndex}
              previousPageLink={routes.sshKnownHostList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.sshKnownHostList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
