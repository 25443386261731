import { TrashIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { gitRepoResourceName } from '../../helpers/util';
import { deleteGitRepository } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { DeleteModal } from '../core/delete_modal';
import { createToast } from '../core/toast';

interface GitRepoDeleteModalProps {
  projectId: string;
  repoId: string;
}

export function GitRepoDeleteModal(props: GitRepoDeleteModalProps) {
  const [open, setOpen] = useState(false);

  const onSubmit = async () => {
    try {
      await deleteGitRepository({
        name: gitRepoResourceName(props.projectId, props.repoId),
      });
      createToast(`Git repository "${props.repoId}" deleted.`);
      routes.gitRepositoryList({
        projectId: props.projectId,
      }).push();
    } catch (err) {
      createToast(`Error deleting git repository: ${err}`);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <TrashIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
        Detach
      </button>
      <DeleteModal
        title="Detach Git repository"
        description={`Are you sure you want to detach ${props.repoId}? This action cannot be undone.`}
        action="Detach"
        onSubmit={onSubmit}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
