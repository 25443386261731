import { useGetUserInfo } from '../../hooks/use_sts_api';

export function UserInfo() {
  const { data } = useGetUserInfo();
  const userInfo = data;

  return (
    <div className="mt-4 max-w-xl overflow-hidden border-t border-b border-gray-200">
      <div className="px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-500 sm:col-span-2 sm:mt-0">
              {userInfo.email}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
