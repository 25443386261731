import { Suspense } from 'react';
import { type Route } from 'type-route';

import { groups, routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { Tabs } from '../core/tabs';
import { APIResourceTable } from './api_resource_table';
import { ClusterHeading } from './cluster_heading';
import { NamespaceTable } from './namespace_table';
import { NodeTable } from './node_table';

interface ClusterDetailProps {
  route: Route<typeof groups.clusterDetail>;
}

export function ClusterDetail(props: ClusterDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Clusters',
      link: routes.clusterList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  const tabs = [
    {
      name: 'Nodes',
      link: routes.clusterDetail({
        projectId: route.params.projectId,
        clusterId: route.params.clusterId,
      }).link,
      current: route.name === 'clusterDetail',
    },
    //{
    //  name: 'Namespaces',
    //  href:
    //    `/projects/${route.params.projectId}/clusters/${route.params.clusterId}/namespaces`,
    //  current: route.name === 'clusterNamespaceList',
    //},
    //{
    //  name: 'API resources',
    //  href:
    //    `/projects/${route.params.projectId}/clusters/${route.params.clusterId}/apiResources`,
    //  current: route.name === 'clusterApiResourceList',
    //},
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ClusterHeading
            projectId={route.params.projectId}
            clusterId={route.params.clusterId}
          />
        </Suspense>
      </ErrorBoundary>
      <Tabs tabs={tabs} />
      {route.name === 'clusterDetail' && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <NodeTable
              projectId={route.params.projectId}
              clusterId={route.params.clusterId}
            />
          </Suspense>
        </ErrorBoundary>
      )}
      {route.name === 'clusterNamespaceList' && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <NamespaceTable
              projectId={route.params.projectId}
              clusterId={route.params.clusterId}
            />
          </Suspense>
        </ErrorBoundary>
      )}
      {route.name === 'clusterApiResourceList' && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <APIResourceTable
              projectId={route.params.projectId}
              clusterId={route.params.clusterId}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  );
}
