import {
  appResourceName,
  formatDate,
  formatDuration,
  formatIncarnation,
  rolloutStateToString,
} from '../../helpers/util';
import {
  useListRollouts,
  useSearchRollouts,
} from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { RolloutProgressBar } from './rollout_progress_bar';
import { RolloutStateBadge } from './rollout_state_badge';

interface RolloutTableProps {
  projectId: string;
  appId?: string;
  showProject: boolean;
  showApp: boolean;
}

export function RolloutTable(props: RolloutTableProps) {
  const appId = props.appId ? props.appId : '-';
  const name = appResourceName(props.projectId, appId);
  const listResult = useListRollouts({
    parent: name,
    pageSize: 15,
    pageToken: '',
  });
  const searchResult = useSearchRollouts({
    parent: name,
    pageSize: 1,
    pageToken: '',
  });

  let data;
  if (props.appId) {
    data = listResult.data;
  } else {
    data = searchResult.data;
  }

  const columnCount = props.showApp ? 8 : 7;
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  ID
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                {props.showApp && (
                  <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                    Application
                  </th>
                )}
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Incarnation
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Assets
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Progress
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Duration
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-2 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Started
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.rollouts.length == 0 && (
                <tr>
                  <td
                    colSpan={columnCount}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500"
                  >
                    No rollouts to display
                  </td>
                </tr>
              )}
              {data.rollouts.map((rollout) => (
                <tr key={rollout.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.rolloutDetail({
                        projectId: rollout.name.split('/')[1],
                        applicationId: rollout.name.split('/')[3],
                        rolloutId: rollout.name.split('/')[5],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {rollout.name.split('/')[5]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <RolloutStateBadge state={rollout.state} />
                  </td>
                  {props.showApp && (
                    <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                      <a
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        href={`/projects/${
                          rollout.name.split('/')[1]
                        }/applications/${rollout.incarnation.split('/')[3]}`}
                      >
                        {rollout.name.split('/')[3]}
                      </a>
                    </td>
                  )}
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm font-medium leading-5 text-gray-900">
                    <a
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      href={`/${rollout.incarnation}`}
                    >
                      {formatIncarnation(rollout.incarnation.split('/')[5])}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-right text-sm leading-5 text-gray-500">
                    {rollout.assetCount}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-900">
                    <RolloutProgressBar rollout={rollout} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-right text-sm leading-5 text-gray-500">
                    {['Succeeded', 'Rolled back'].includes(
                        rolloutStateToString(rollout.state),
                      )
                      ? formatDuration(
                        rollout.updateTime.toDate().valueOf() -
                          rollout.createTime.toDate().valueOf(),
                      )
                      : formatDuration(
                        new Date().valueOf() -
                          rollout.createTime.toDate().valueOf(),
                      )}
                  </td>
                  <td className="w-full border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {formatDate(rollout.createTime.toDate())}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.rollouts.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.rollouts.length}
              startIndex={data.startIndex}
              previousPageLink={routes.rolloutList({
                projectId: props.projectId,
                applicationId: props.appId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.rolloutList({
                projectId: props.projectId,
                applicationId: props.appId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
