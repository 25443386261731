import { Suspense, useEffect } from 'react';

import { useListProjects } from '../hooks/use_organization_api';
import { routes } from '../router';
import { ErrorBoundary } from './core/error_boundary';
import { Spinner } from './core/spinner';

export function Home() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <HomeRedirect />
      </Suspense>
    </ErrorBoundary>
  );
}

function HomeRedirect() {
  const { data } = useListProjects({ pageSize: 1, pageToken: '' });
  useEffect(() => {
    if (data.projects.length === 0) {
      routes.projectCreate().replace();
    } else {
      routes.projectDetail({ projectId: data.projects[0].name.split('/')[1] })
        .replace();
    }
  }, [data]);
  return <div>Redirecting...</div>;
}
