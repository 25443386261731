import React, { ReactNode } from 'react';

import {
  isRpcPermissionDeniedError,
  isRpcUnauthenticatedError,
} from '../../helpers/util';
import { routes } from '../../router';
import { PermissionDeniedAlert } from './permission_denied_alert';
import { UnexpectedErrorAlert } from './unexpected_error_alert';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: any;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error: error,
    };
  }

  render() {
    if (this.state.hasError) {
      if (isRpcPermissionDeniedError(this.state.error)) {
        return <PermissionDeniedAlert />;
      } else if (isRpcUnauthenticatedError(this.state.error)) {
        // TODO: Show snack bar to indicate session has expired.
        routes.login().push();
        return <div>Redirecting...</div>;
      }
      return <UnexpectedErrorAlert />;
    }
    return this.props.children;
  }
}
