import { machineResourceName } from '../../helpers/util';
import { useGetMachine } from '../../hooks/use_compute_api';
import { MachineDeleteModal } from './machine_delete_modal';
import { MachineStateBadge } from './machine_state_badge';

interface MachineHeadingProps {
  projectId: string;
  machineId: string;
}

export function MachineHeading(props: MachineHeadingProps) {
  const name = machineResourceName(props.projectId, props.machineId);
  const { data } = useGetMachine({ name: name });
  const machine = data;

  return (
    <div className="my-2 md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-medium text-gray-900">
          {props.machineId}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <MachineStateBadge state={machine.state} />
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
        <div className="space-x-3">
          <MachineDeleteModal
            projectId={props.projectId}
            machineId={props.machineId}
          />
        </div>
      </div>
    </div>
  );
}
