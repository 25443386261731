import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import useSWR from 'swr';

import { ComputeService } from '../../proto/annealing/compute/v1/service_connect';

const refreshInterval = 2000;
const transport = createGrpcWebTransport({ baseUrl: '' });

const client = createPromiseClient(ComputeService, transport);
const opts = { refreshInterval: refreshInterval, suspense: true };

async function listMachines(req: any) {
  return await client.listMachines(req);
}

async function getMachine(req: any) {
  return await client.getMachine(req);
}

async function listClusters(req: any) {
  return await client.listClusters(req);
}

async function getCluster(req: any) {
  return await client.getCluster(req);
}

export async function createMachine(req: any) {
  return await client.createMachine(req);
}

export async function deleteMachine(req: any) {
  return await client.deleteMachine(req);
}

export async function createCluster(req: any) {
  return await client.createCluster(req);
}

export async function deleteCluster(req: any) {
  return await client.deleteCluster(req);
}

export function useListMachines(req: any) {
  return useSWR(
    ['annealing.compute.v1.ComputeService/ListMachines', req],
    ([_m, req]) => listMachines(req),
    opts,
  );
}

export function useListClusters(req: any) {
  return useSWR(
    ['annealing.compute.v1.ComputeService/ListClusters', req],
    ([_m, req]) => listClusters(req),
    opts,
  );
}

export function useGetMachine(req: any) {
  return useSWR(
    ['annealing.compute.v1.ComputeService/GetMachine', req],
    ([_m, req]) => getMachine(req),
    opts,
  );
}

export function useGetCluster(req: any) {
  return useSWR(
    ['annealing.compute.v1.ComputeService/GetCluster', req],
    ([_m, req]) => getCluster(req),
    opts,
  );
}
