import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';

import { projectResourceName } from '../../helpers/util';
import { createSSHKnownHost } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { createToast } from '../core/toast';

interface SSHKnownHostCreateProps {
  route: Route<typeof routes.sshKnownHostCreate>;
}

interface SSHKnownHostCreateFormData {
  name: string;
  hostname: string;
  algorithm: string;
  fingerprint: string;
}

export function SSHKnownHostCreate(props: SSHKnownHostCreateProps) {
  const { route } = props;
  const { register, handleSubmit } = useForm<SSHKnownHostCreateFormData>();

  const parent = projectResourceName(route.params.projectId);

  const onSubmit = async (data: SSHKnownHostCreateFormData) => {
    try {
      await createSSHKnownHost({
        parent: parent,
        sshKnownHost: {
          name: '',
          state: 0,
          hostname: data.hostname,
          algorithm: data.algorithm,
          fingerprintSha256: data.fingerprint,
        },
        sshKnownHostId: data.name,
      });
      createToast(`SSH known host "${data.name}" created.`);
      routes.sshKnownHostDetail({
        projectId: route.params.projectId,
        sshKnownHostId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating SSH known host: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'SSH known hosts',
      link: routes.sshKnownHostList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            New SSH known host
          </h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        An SSH known host is a trusted fingerprint that allows you to access
        private Git repositories.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                SSH known host name
              </label>
              <div className="mt-1">
                <input
                  {...register('name')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="hostname"
                className="block text-sm font-medium text-gray-700"
              >
                Hostname
              </label>
              <div className="mt-1">
                <input
                  {...register('hostname')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="algorithm"
                className="block text-sm font-medium text-gray-700"
              >
                Algorithm
              </label>
              <div className="mt-1">
                <select
                  {...register('algorithm')}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option>ecdsa-sha2-nistp256</option>
                  <option>ssh-ed25519</option>
                  <option>ssh-rsa</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="fingerprint"
                className="block text-sm font-medium text-gray-700"
              >
                Fingerprint
              </label>
              <div className="mt-1">
                <input
                  {...register('fingerprint')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.sshKnownHostList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="ml-3 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
