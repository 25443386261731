import { projectResourceName } from '../../helpers/util';
import { useGetProject } from '../../hooks/use_organization_api';

interface ProjectStatsProps {
  projectId: string;
}

export function ProjectStats(props: ProjectStatsProps) {
  const name = projectResourceName(props.projectId);
  const { data } = useGetProject({ name: name });
  const project = data;

  return (
    <div className="mt-5 md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <div>
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="truncate text-sm font-medium leading-5 text-gray-500">
                    Applications
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold leading-9 text-gray-900">
                    {project.appCount}
                  </dd>
                </dl>
              </div>
            </div>
            <div className="overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="truncate text-sm font-medium leading-5 text-gray-500">
                    Clusters
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold leading-9 text-gray-900">
                    {project.clusterCount}
                  </dd>
                </dl>
              </div>
            </div>
            <div className="overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="truncate text-sm font-medium leading-5 text-gray-500">
                    Git Repositories
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold leading-9 text-gray-900">
                    {project.gitRepositoryCount}
                  </dd>
                </dl>
              </div>
            </div>
            <div className="overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="truncate text-sm font-medium leading-5 text-gray-500">
                    Objects
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold leading-9 text-gray-900">
                    {project.assetCount}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
