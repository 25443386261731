import { operationResourceName } from '../../helpers/util';
import { useGetOperation } from '../../hooks/use_operations_api';

interface OperationHeadingProps {
  projectId: string;
  operationId: string;
}

export function OperationHeading(props: OperationHeadingProps) {
  const name = operationResourceName(props.projectId, props.operationId);
  const { data } = useGetOperation({ name: name });
  const operation = data;
  console.log(operation);

  return (
    <div className="my-2 md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-medium text-gray-900">
          {props.operationId}
        </h2>
      </div>
    </div>
  );
}
