import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { RoleBindingDeleteModal } from './role_binding_delete_modal';
import { RoleBindingInfo } from './role_binding_info';

interface RoleBindingDetailProps {
  route: Route<typeof routes.roleBindingDetail>;
}

export function RoleBindingDetail(props: RoleBindingDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Role bindings',
      link: routes.roleBindingList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="my-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            {route.params.roleBindingId}
          </h1>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
          <RoleBindingDeleteModal
            projectId={route.params.projectId}
            roleBindingId={route.params.roleBindingId}
          />
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <RoleBindingInfo
            projectId={route.params.projectId}
            roleBindingId={route.params.roleBindingId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
