import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { routes } from '../../router';
import { GoogleLogo } from '../icons/google_logo';

interface LoginFormData {
  email: string;
}

export function Login() {
  const { register, handleSubmit, formState } = useForm<LoginFormData>();
  const [magicLink, setMagicLink] = useState('');
  const { isSubmitting } = formState;

  const onSubmit = async (data: LoginFormData) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: data.email }),
    };
    const response = await fetch('/auth/magic:create', requestOptions);
    if (response.status == 200) {
      setMagicLink(data.email);
    } else {
      // TODO: Show a better error.
      console.log(response.status);
    }
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="border border-gray-200 bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          {magicLink && (
            <div className="mt-8 space-y-6">
              <div className="text-center text-2xl font-medium">
                We&apos;ve sent you a magic link!
              </div>
              <div className="text-center text-base">
                Check your email at{' '}
                <span className="font-medium">{magicLink}</span>{' '}
                to finish the sign-in process.
              </div>
              <div className="text-center">
                <a
                  href="/"
                  className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Go back home<span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          )}
          {!magicLink && (
            <div>
              <h2 className="text-3xl font-semibold leading-9 text-gray-900">
                Sign in
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                New here?{' '}
                <a
                  {...routes.signUp().link}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Create an account
                </a>
                .
              </p>
              <form
                className="mt-8 space-y-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      {...register('email', { required: true })}
                      id="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-75"
                  >
                    Continue
                  </button>
                </div>
              </form>
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 uppercase text-gray-500">
                      Or
                    </span>
                  </div>
                </div>
                <div className="mt-6 inline-flex w-full rounded-md shadow-sm">
                  <a
                    href="/auth/google/login"
                    role="button"
                    className="inline-flex w-full items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    aria-label="Continue with Google"
                  >
                    <span className="flex w-full justify-center">
                      <GoogleLogo />
                      Sign in with Google
                    </span>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
