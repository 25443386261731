import { TrashIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { serviceAccountResourceName } from '../../helpers/util';
import { deleteServiceAccount } from '../../hooks/use_iam_api';
import { routes } from '../../router';
import { DeleteModal } from '../core/delete_modal';
import { createToast } from '../core/toast';

interface ServiceAccountDeleteModalProps {
  projectId: string;
  accountId: string;
}

export function ServiceAccountDeleteModal(
  props: ServiceAccountDeleteModalProps,
) {
  const [open, setOpen] = useState(false);

  const onSubmit = async () => {
    try {
      await deleteServiceAccount({
        name: serviceAccountResourceName(props.projectId, props.accountId),
      });
      createToast(`Service account "${props.accountId}" deleted.`);
      routes.serviceAccountList({
        projectId: props.projectId,
      }).push();
    } catch (err) {
      createToast(`Error deleting service account: ${err}`);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <TrashIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
        Delete
      </button>
      <DeleteModal
        title="Delete service account"
        description={`Are you sure you want to delete ${props.accountId}? This action cannot be undone.`}
        action="Delete"
        onSubmit={onSubmit}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
