import { CircleStackIcon, ServerStackIcon } from '@heroicons/react/20/solid';

import { appResourceName, rolloutStateToString } from '../../helpers/util';
import {
  useGetApplication,
  useListIncarnations,
  useListRollouts,
} from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { GitBranchIcon } from '../icons/git_branch';
import { ApplicationDeleteModal } from './application_delete_modal';
import { ApplicationSyncModal } from './application_sync_modal';
import { AssetHealthStateBadge } from './asset_health_state_badge';
import { AssetSyncStateBadge } from './asset_sync_state_badge';
import { IncarnationSummaryBadge } from './incarnation_summary_badge';
import { RolloutSummaryBadge } from './rollout_summary_badge';

interface ApplicationHeadingProps {
  projectId: string;
  appId: string;
}

export function ApplicationHeading(props: ApplicationHeadingProps) {
  const name = appResourceName(props.projectId, props.appId);

  const { data } = useGetApplication({ name: name });
  const incarnationsResult = useListIncarnations({
    parent: name,
    pageSize: 1,
    pageToken: '',
  });
  const rolloutsResult = useListRollouts({
    parent: name,
    pageSize: 1,
    pageToken: '',
  });

  const application = data;
  const incarnations = incarnationsResult.data.incarnations;
  const rollouts = rolloutsResult.data.rollouts;

  return (
    <div className="my-2 md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-medium text-gray-900">{props.appId}</h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <AssetHealthStateBadge state={application.healthState} />
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <AssetSyncStateBadge state={application.syncState} />
          </div>
          {application.source.git && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CircleStackIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <a
                {...routes.gitRepositoryDetail({
                  projectId: props.projectId,
                  gitRepositoryId:
                    application.source.git.repository.split('/')[3],
                }).link}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {application.source.git.repository.split('/')[3]}
              </a>
            </div>
          )}
          {application.source.git &&
            application.source.git.revision.startsWith('refs/heads/') && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <GitBranchIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {application.source.git.revision.substring(11)}
            </div>
          )}
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <ServerStackIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <a
              {...routes.clusterDetail({
                projectId: props.projectId,
                clusterId: application.target.cluster.split('/')[3],
              }).link}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              {application.target.cluster.split('/')[3]}
            </a>
          </div>
          {incarnations.length > 0 && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <IncarnationSummaryBadge incarnation={incarnations[0]} />
            </div>
          )}
          {rollouts.length > 0 && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <RolloutSummaryBadge rollout={rollouts[0]} />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
        <div className="space-x-3">
          <ApplicationSyncModal
            projectId={props.projectId}
            appId={props.appId}
            desiredIncarnationId={application.desiredIncarnation.split('/')[5]}
            targetIncarnationId={application.targetIncarnation.split('/')[5]}
            latestIncarnationId={incarnations.length > 0
              ? incarnations[0].name.split('/')[5]
              : ''}
            rolloutInProgress={rollouts.length > 0 &&
              (rolloutStateToString(rollouts[0].state) === 'Pending' ||
                rolloutStateToString(rollouts[0].state) === 'Running')}
          />
          <ApplicationDeleteModal
            projectId={props.projectId}
            appId={props.appId}
          />
        </div>
      </div>
    </div>
  );
}
