import {
  ExclamationCircleIcon,
  HeartIcon,
  NoSymbolIcon,
  PauseIcon,
} from '@heroicons/react/20/solid';
import { assetHealthStateToString } from '../../helpers/util';
import { CircularProgress } from '../core/circular_progress';

interface AssetHealthStateBadgeProps {
  state: number;
}

export function AssetHealthStateBadge(props: AssetHealthStateBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {assetHealthStateToString(props.state) === 'Healthy' && (
        <HeartIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {assetHealthStateToString(props.state) === 'Degraded' && (
        <ExclamationCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
          aria-hidden="true"
        />
      )}
      {assetHealthStateToString(props.state) === 'Suspended' && (
        <PauseIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      {assetHealthStateToString(props.state) === 'Missing' && (
        <NoSymbolIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
          aria-hidden="true"
        />
      )}
      {assetHealthStateToString(props.state) === 'Progressing' && (
        <CircularProgress className="mr-2 ml-0.5 h-4 w-4 animate-spin text-blue-500" />
      )}
      {assetHealthStateToString(props.state) === 'Unspecified'
        ? '\u2014'
        : assetHealthStateToString(props.state)}
    </span>
  );
}
