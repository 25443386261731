import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { SSHKeyDeleteModal } from './ssh_key_delete_modal';
import { SSHKeyInfo } from './ssh_key_info';

interface SSHKeyDetailProps {
  route: Route<typeof routes.sshKeyDetail>;
}

export function SSHKeyDetail(props: SSHKeyDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'SSH keys',
      link: routes.sshKeyList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="my-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            {route.params.sshKeyId}
          </h1>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
          <SSHKeyDeleteModal
            projectId={route.params.projectId}
            keyId={route.params.sshKeyId} />
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <SSHKeyInfo
            projectId={route.params.projectId}
            keyId={route.params.sshKeyId} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
