// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file annealing/organization/v1/resources.proto (package annealing.organization.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Project represents a permission boundary.
 *
 * @generated from message annealing.organization.v1.Project
 */
export class Project extends Message<Project> {
  /**
   * The resource name of the project.
   * Format: projects/{project}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: int32 cluster_count = 3;
   */
  clusterCount = 0;

  /**
   * @generated from field: int32 asset_count = 5;
   */
  assetCount = 0;

  /**
   * @generated from field: int32 app_count = 6;
   */
  appCount = 0;

  /**
   * @generated from field: int32 rollout_count = 7;
   */
  rolloutCount = 0;

  /**
   * @generated from field: int32 chart_repository_count = 8;
   */
  chartRepositoryCount = 0;

  /**
   * @generated from field: int32 git_repository_count = 9;
   */
  gitRepositoryCount = 0;

  /**
   * @generated from field: int32 ssh_key_count = 10;
   */
  sshKeyCount = 0;

  /**
   * @generated from field: int32 ssh_known_host_count = 11;
   */
  sshKnownHostCount = 0;

  /**
   * @generated from field: int32 role_binding_count = 12;
   */
  roleBindingCount = 0;

  /**
   * @generated from field: int32 identity_provider_count = 13;
   */
  identityProviderCount = 0;

  /**
   * @generated from field: int32 service_account_count = 14;
   */
  serviceAccountCount = 0;

  /**
   * @generated from field: int32 ignore_difference_count = 15;
   */
  ignoreDifferenceCount = 0;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 16;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 17;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<Project>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.organization.v1.Project";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cluster_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "asset_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "app_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "rollout_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "chart_repository_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "git_repository_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "ssh_key_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "ssh_known_host_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "role_binding_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "identity_provider_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "service_account_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "ignore_difference_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "create_time", kind: "message", T: Timestamp },
    { no: 17, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Project {
    return new Project().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJsonString(jsonString, options);
  }

  static equals(a: Project | PlainMessage<Project> | undefined, b: Project | PlainMessage<Project> | undefined): boolean {
    return proto3.util.equals(Project, a, b);
  }
}

