import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';

import { appResourceName, appRevisionResourceName } from '../../helpers/util';
import { updateApplication } from '../../hooks/use_delivery_api';
import { createToast } from '../core/toast';

interface ApplicationSyncModalProps {
  projectId: string;
  appId: string;
  desiredIncarnationId: string;
  targetIncarnationId: string;
  latestIncarnationId: string;
  rolloutInProgress: boolean;
}

export function ApplicationSyncModal(props: ApplicationSyncModalProps) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const onSubmit = async () => {
    try {
      await updateApplication({
        application: {
          name: appResourceName(props.projectId, props.appId),
          syncState: 0,
          healthState: 0,
          assetCount: 0,
          source: {
            git: {
              repository: '',
              revision: '',
              path: '',
              revisionCommit: '',
              pathCommit: '',
            },
          },
          target: {
            cluster: '',
            namespace: '',
          },
          desiredIncarnation: appRevisionResourceName(
            props.projectId,
            props.appId,
            props.latestIncarnationId,
          ),
          targetIncarnation: '',
        },
        updateMask: {
          paths: ['desired_incarnation'],
        },
      });
      setOpen(false);
      createToast(`Application sync triggered.`);
    } catch (err) {
      createToast(`Error triggering sync for application: ${err}`);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        disabled={props.rolloutInProgress ||
          props.latestIncarnationId === '' ||
          props.desiredIncarnationId === '' ||
          props.latestIncarnationId === props.desiredIncarnationId}
      >
        <ArrowPathIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
        Sync
      </button>

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <InformationCircleIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-red-600" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900">
                    Sync application
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to sync this application?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={onSubmit}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Sync
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
