import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
} from '@heroicons/react/20/solid';

import { serviceAccountStateToString } from '../../helpers/util';

interface ServiceAccountStateBadgeProps {
  state: number;
}

export function ServiceAccountStateBadge(props: ServiceAccountStateBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {serviceAccountStateToString(props.state) === 'Active' && (
        <CheckCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {serviceAccountStateToString(props.state) === 'Pending' && (
        <EllipsisHorizontalCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      {serviceAccountStateToString(props.state)}
    </span>
  );
}
