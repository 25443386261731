interface OKDIconProps {
  className: string;
  ariaHidden: string;
}

export function OKDIcon(props: OKDIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <title>OKD</title>
      <path d="M6 12C2.68629 12 0 9.3137 0 6C0 2.68629 2.68629 0 6 0C9.3137 0 12 2.68629 12 6C12 9.3137 9.3137 12 6 12zM6 10C8.2091 10 10 8.2091 10 6C10 3.79086 8.2091 2 6 2C3.79086 2 2 3.79086 2 6C2 8.2091 3.79086 10 6 10z" />
    </svg>
  );
}
