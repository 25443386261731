import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';
import { sshKnownHostStateToString } from '../../helpers/util';

interface SSHKnownHostStateBadgeProps {
  state: number;
}

export function SSHKnownHostStateBadge(props: SSHKnownHostStateBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {sshKnownHostStateToString(props.state) === 'Active' && (
        <CheckCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {sshKnownHostStateToString(props.state) === 'Error' && (
        <ExclamationCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
          aria-hidden="true"
        />
      )}
      {sshKnownHostStateToString(props.state) === 'Pending' && (
        <EllipsisHorizontalCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      {sshKnownHostStateToString(props.state)}
    </span>
  );
}
