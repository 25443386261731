import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import useSWR from 'swr';

import { OrganizationService } from '../../proto/annealing/organization/v1/service_connect';

const refreshInterval = 2000;
const transport = createGrpcWebTransport({ baseUrl: '' });

const client = createPromiseClient(OrganizationService, transport);
const opts = { refreshInterval: refreshInterval, suspense: true };

async function listProjects(req: any) {
  return await client.listProjects(req);
}

async function getProject(req: any) {
  return await client.getProject(req);
}

export async function createProject(req: any) {
  return await client.createProject(req);
}

export function useListProjects(req: any) {
  return useSWR(
    ['annealing.organization.v1.OrganizationService/ListProject', req],
    ([_m, req]) =>
      listProjects(req),
    opts,
  );
}

export function useGetProject(req: any) {
  return useSWR(
    ['annealing.organizatino.v1.OrganizationService/GetProject', req],
    ([_m, req]) =>
      getProject(req),
    opts,
  );
}
