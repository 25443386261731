import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';

import { Rollout } from '../../../proto/annealing/delivery/v1/resources_pb';
import { formatIncarnation, rolloutStateToString } from '../../helpers/util';
import { routes } from '../../router';
import { CircularProgress } from '../core/circular_progress';

interface RolloutSummaryBadgeProps {
  rollout: Rollout;
}

export function RolloutSummaryBadge(props: RolloutSummaryBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {rolloutStateToString(props.rollout.state) === 'Succeeded' && (
        <CheckCircleIcon
          className="h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {rolloutStateToString(props.rollout.state) === 'Failed' && (
        <ExclamationCircleIcon
          className="h-5 w-5 flex-shrink-0 text-red-400"
          aria-hidden="true"
        />
      )}
      {rolloutStateToString(props.rollout.state) === 'Pending' && (
        <EllipsisHorizontalCircleIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      {rolloutStateToString(props.rollout.state) === 'Running' && (
        <CircularProgress className="ml-0.5 mr-0.5 h-4 w-4 animate-spin text-blue-500" />
      )}
      <a
        className="ml-1.5 mr-1 font-medium text-indigo-600 hover:text-indigo-500"
        {...routes.rolloutDetail({
          projectId: props.rollout.name.split('/')[1],
          applicationId: props.rollout.name.split('/')[3],
          rolloutId: props.rollout.name.split('/')[5],
        }).link}
      >
        {props.rollout.name.split('/')[5]}
      </a>
      ({formatIncarnation(props.rollout.incarnation.split('/')[5])})
    </span>
  );
}
