import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';
import { z } from 'zod';

import { projectResourceName } from '../../helpers/util';
import { createCluster } from '../../hooks/use_compute_api';
import { useGetUserInfo } from '../../hooks/use_sts_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { createToast } from '../core/toast';
import { MachineChooser } from './machine_chooser';

interface ClusterCreateProps {
  route: Route<typeof routes.computeClusterCreate>;
}

interface ClusterCreateFormData {
  name: string;
  version: string;
  machines: string[];
  podIpv4CidrBlock: string;
  serviceIpv4CidrBlock: string;
  adminUser: string;
}

const letterStartRegExp = new RegExp('^[a-z]');
const letterNumberEndRegExp = new RegExp('[a-z0-9]$');
const nameRegExp = new RegExp('^[-a-z0-9]*$');
const cidrBlockRegExp = new RegExp(
  '^([0-9]{1,3}\.){3}[0-9]{1,3}($|/(16|17|18|19|20|21|22|23|24))$',
);
const versionRegExp = new RegExp('^1\.(26|27)$');

export function ClusterCreate(props: ClusterCreateProps) {
  const { route } = props;
  const versions = ['1.27', '1.26'];
  const schema = z.object({
    name: z
      .string()
      .min(1, 'Name is required')
      .max(63, 'Name may be at most 63 characters')
      .regex(nameRegExp, {
        message:
          'Name must consist of lowercase letters (a-z), numbers, and hyphens',
      })
      .regex(letterStartRegExp, {
        message: 'Name must start with a lowercase letter',
      })
      .regex(letterNumberEndRegExp, {
        message: 'Name must end with a lowercase letter or a number',
      }),
    version: z.string().min(1, 'Version is required').regex(versionRegExp, {
      message: 'Version must be a supported Kubernetes version',
    }),
    podIpv4CidrBlock: z.string().min(1, 'Pod IPv4 CIDR block is required')
      .regex(cidrBlockRegExp, {
        message: 'Pod IPv4 CIDR block must be valid',
      }),
    serviceIpv4CidrBlock: z.string().min(
      1,
      'Service IPv4 CIDR block is required',
    ).regex(cidrBlockRegExp, {
      message: 'Service IPv4 CIDR block must be valid',
    }),
    adminUser: z.string().min(1, 'Admin user is required'),
    machines: z.string().array().refine(
      (val) => val.length == 1 || val.length == 3,
      { message: 'Machine count must be exactly 1 or 3' },
    ),
  });

  const { data } = useGetUserInfo();
  const { register, handleSubmit, formState, control } = useForm<
    ClusterCreateFormData
  >({
    defaultValues: {
      name: '',
      version: versions[0],
      machines: [],
      podIpv4CidrBlock: '10.42.0.0/16',
      serviceIpv4CidrBlock: '10.43.0.0/16',
      adminUser: data.email.split('/')[1],
    },
    resolver: zodResolver(schema),
  });
  const parent = projectResourceName(route.params.projectId);

  const { isSubmitting, errors } = formState;

  const onSubmit = async (data: ClusterCreateFormData) => {
    try {
      await createCluster({
        parent: parent,
        cluster: {
          name: '',
          state: 0,
          description: '',
          uid: '',
          endpoint: '',
          version: data.version,
          certificateAuthority: '',
          controlPlane: {
            machines: data.machines,
          },
          networking: {
            podIpv4CidrBlocks: [data.podIpv4CidrBlock],
            serviceIpv4CidrBlocks: [data.serviceIpv4CidrBlock],
          },
          authorization: {
            adminUsers: [data.adminUser],
          },
        },
        clusterId: data.name,
        validateOnly: false,
      });
      createToast(`Cluster "${data.name}" created.`);
      routes.computeClusterDetail({
        projectId: route.params.projectId,
        clusterId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating cluster: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'Clusters',
      link: routes.computeClusterList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">New cluster</h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        A Kubernetes cluster can be created using attached machines.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Cluster name
              </label>
              <div
                className={`mt-1 ${
                  errors.name ? 'relative rounded-md shadow-sm' : ''
                }`}
              >
                <input
                  {...register('name')}
                  id="name"
                  type="text"
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors.name
                      ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                      : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  }`}
                  aria-invalid={!!errors.name}
                />
                {errors.name && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p className="mt-2 text-sm text-red-600" id="name-error">
                {errors.name?.message}
              </p>
            </div>
            <div>
              <label
                htmlFor="version"
                className="block text-sm font-medium text-gray-700"
              >
                Kubernetes version
              </label>
              <div className="mt-1">
                <select
                  {...register('version')}
                  id="version"
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors.version
                      ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                      : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  }`}
                  aria-invalid={!!errors.version}
                >
                  {versions.map((version) => (
                    <option key={version} value={version}>{version}</option>
                  ))}
                </select>
                {errors.version && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p className="mt-2 text-sm text-red-600" id="version-error">
                {errors.version?.message}
              </p>
            </div>
            <div>
              <div className="mt-1">
                <ErrorBoundary>
                  <Suspense fallback={<Spinner />}>
                    <MachineChooser
                      projectId={route.params.projectId}
                      control={control} />
                  </Suspense>
                </ErrorBoundary>
              </div>
              <p className="mt-2 text-sm text-red-600" id="machines-error">
                {errors.machines?.message}
              </p>
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Networking
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Kubernetes networking configuration options.
              </p>
            </div>
            <div>
              <label
                htmlFor="podIpv4CidrBlock"
                className="block text-sm font-medium text-gray-700"
              >
                Pod IPv4 CIDR block
              </label>
              <div
                className={`mt-1 ${
                  errors.podIpv4CidrBlock ? 'relative rounded-md shadow-sm' : ''
                }`}
              >
                <input
                  {...register('podIpv4CidrBlock')}
                  id="podIpv4CidrBlock"
                  type="text"
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors.podIpv4CidrBlock
                      ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                      : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  }`}
                  aria-invalid={!!errors.podIpv4CidrBlock}
                />
                {errors.podIpv4CidrBlock && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p
                className="mt-2 text-sm text-red-600"
                id="podIpv4CidrBlock-error">
                {errors.podIpv4CidrBlock?.message}
              </p>
            </div>
            <div>
              <label
                htmlFor="serviceIpv4CidrBlock"
                className="block text-sm font-medium text-gray-700"
              >
                Service IPv4 CIDR block
              </label>
              <div
                className={`mt-1 ${
                  errors.serviceIpv4CidrBlock
                    ? 'relative rounded-md shadow-sm'
                    : ''
                }`}
              >
                <input
                  {...register('serviceIpv4CidrBlock')}
                  id="serviceIpv4CidrBlock"
                  type="text"
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors.serviceIpv4CidrBlock
                      ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                      : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  }`}
                  aria-invalid={!!errors.serviceIpv4CidrBlock}
                />
                {errors.serviceIpv4CidrBlock && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p
                className="mt-2 text-sm text-red-600"
                id="serviceIpv4CidrBlock-error">
                {errors.serviceIpv4CidrBlock?.message}
              </p>
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Authorization
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Users with administrative permissions in the cluste
                out-of-the-box.
              </p>
            </div>
            <div>
              <label
                htmlFor="adminUser"
                className="block text-sm font-medium text-gray-700"
              >
                Admin user
              </label>
              <div
                className={`mt-1 ${
                  errors.adminUser ? 'relative rounded-md shadow-sm' : ''
                }`}
              >
                <input
                  {...register('adminUser')}
                  id="adminUser"
                  type="text"
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors.adminUser
                      ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                      : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  }`}
                  aria-invalid={!!errors.adminUser}
                />
                {errors.adminUser && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p
                className="mt-2 text-sm text-red-600"
                id="adminUser-error">
                {errors.adminUser?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.computeClusterList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className={`ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                isSubmitting
                  ? 'cursor-not-allowed opacity-50'
                  : 'hover:bg-indigo-700'
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Creating...' : 'Create'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
