import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';

import { Incarnation } from '../../../proto/annealing/delivery/v1/resources_pb';
import {
  appRevisionStateToString,
  formatIncarnation,
} from '../../helpers/util';
import { routes } from '../../router';
import { CircularProgress } from '../core/circular_progress';

interface IncarnationSummaryBadgeProps {
  incarnation: Incarnation;
}

export function IncarnationSummaryBadge(props: IncarnationSummaryBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {appRevisionStateToString(props.incarnation.state) === 'Rendered' && (
        <CheckCircleIcon
          className="h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {appRevisionStateToString(props.incarnation.state) === 'Failed' && (
        <ExclamationCircleIcon
          className="h-5 w-5 flex-shrink-0 text-red-400"
          aria-hidden="true"
        />
      )}
      {appRevisionStateToString(props.incarnation.state) === 'Pending' && (
        <EllipsisHorizontalCircleIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      {appRevisionStateToString(props.incarnation.state) === 'Rendering' && (
        <CircularProgress className="ml-0.5 mr-0.5 h-4 w-4 animate-spin text-blue-500" />
      )}
      <a
        {...routes.incarnationDetail({
          projectId: props.incarnation.name.split('/')[1],
          applicationId: props.incarnation.name.split('/')[3],
          incarnationId: props.incarnation.name.split('/')[5],
        }).link}
        className="ml-1.5 font-medium text-indigo-600 hover:text-indigo-500">
        {formatIncarnation(props.incarnation.name.split('/')[5])}
      </a>
      {props.incarnation.source.git.revisionCommit !== '' && (
        <span className="ml-1 text-gray-500">
          ({props.incarnation.source.git.revisionCommit.substring(0, 7)})
        </span>
      )}
    </span>
  );
}
