// Copyright 2020 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file google/longrunning/operations.proto (package google.longrunning, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Any, Duration, Message, MethodOptions, proto3 } from "@bufbuild/protobuf";
import { Status } from "../rpc/status_pb.js";

/**
 * This resource represents a long-running operation that is the result of a
 * network API call.
 *
 * @generated from message google.longrunning.Operation
 */
export class Operation extends Message<Operation> {
  /**
   * The server-assigned name, which is only unique within the same service that
   * originally returns it. If you use the default HTTP mapping, the
   * `name` should be a resource name ending with `operations/{unique_id}`.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Service-specific metadata associated with the operation.  It typically
   * contains progress information and common metadata such as create time.
   * Some services might not provide such metadata.  Any method that returns a
   * long-running operation should document the metadata type, if any.
   *
   * @generated from field: google.protobuf.Any metadata = 2;
   */
  metadata?: Any;

  /**
   * If the value is `false`, it means the operation is still in progress.
   * If `true`, the operation is completed, and either `error` or `response` is
   * available.
   *
   * @generated from field: bool done = 3;
   */
  done = false;

  /**
   * The operation result, which can be either an `error` or a valid `response`.
   * If `done` == `false`, neither `error` nor `response` is set.
   * If `done` == `true`, exactly one of `error` or `response` is set.
   *
   * @generated from oneof google.longrunning.Operation.result
   */
  result: {
    /**
     * The error result of the operation in case of failure or cancellation.
     *
     * @generated from field: google.rpc.Status error = 4;
     */
    value: Status;
    case: "error";
  } | {
    /**
     * The normal response of the operation in case of success.  If the original
     * method returns no data on success, such as `Delete`, the response is
     * `google.protobuf.Empty`.  If the original method is standard
     * `Get`/`Create`/`Update`, the response should be the resource.  For other
     * methods, the response should have the type `XxxResponse`, where `Xxx`
     * is the original method name.  For example, if the original method name
     * is `TakeSnapshot()`, the inferred response type is
     * `TakeSnapshotResponse`.
     *
     * @generated from field: google.protobuf.Any response = 5;
     */
    value: Any;
    case: "response";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Operation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.Operation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: Any },
    { no: 3, name: "done", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "error", kind: "message", T: Status, oneof: "result" },
    { no: 5, name: "response", kind: "message", T: Any, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Operation {
    return new Operation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Operation {
    return new Operation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Operation {
    return new Operation().fromJsonString(jsonString, options);
  }

  static equals(a: Operation | PlainMessage<Operation> | undefined, b: Operation | PlainMessage<Operation> | undefined): boolean {
    return proto3.util.equals(Operation, a, b);
  }
}

/**
 * The request message for [Operations.GetOperation][google.longrunning.Operations.GetOperation].
 *
 * @generated from message google.longrunning.GetOperationRequest
 */
export class GetOperationRequest extends Message<GetOperationRequest> {
  /**
   * The name of the operation resource.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetOperationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.GetOperationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOperationRequest {
    return new GetOperationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOperationRequest {
    return new GetOperationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOperationRequest {
    return new GetOperationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOperationRequest | PlainMessage<GetOperationRequest> | undefined, b: GetOperationRequest | PlainMessage<GetOperationRequest> | undefined): boolean {
    return proto3.util.equals(GetOperationRequest, a, b);
  }
}

/**
 * The request message for [Operations.ListOperations][google.longrunning.Operations.ListOperations].
 *
 * @generated from message google.longrunning.ListOperationsRequest
 */
export class ListOperationsRequest extends Message<ListOperationsRequest> {
  /**
   * The name of the operation's parent resource.
   *
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * The standard list filter.
   *
   * @generated from field: string filter = 1;
   */
  filter = "";

  /**
   * The standard list page size.
   *
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * The standard list page token.
   *
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListOperationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.ListOperationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "filter", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOperationsRequest {
    return new ListOperationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOperationsRequest {
    return new ListOperationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOperationsRequest {
    return new ListOperationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListOperationsRequest | PlainMessage<ListOperationsRequest> | undefined, b: ListOperationsRequest | PlainMessage<ListOperationsRequest> | undefined): boolean {
    return proto3.util.equals(ListOperationsRequest, a, b);
  }
}

/**
 * The response message for [Operations.ListOperations][google.longrunning.Operations.ListOperations].
 *
 * @generated from message google.longrunning.ListOperationsResponse
 */
export class ListOperationsResponse extends Message<ListOperationsResponse> {
  /**
   * A list of operations that matches the specified filter in the request.
   *
   * @generated from field: repeated google.longrunning.Operation operations = 1;
   */
  operations: Operation[] = [];

  /**
   * The standard List next-page token.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListOperationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.ListOperationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operations", kind: "message", T: Operation, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOperationsResponse {
    return new ListOperationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOperationsResponse {
    return new ListOperationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOperationsResponse {
    return new ListOperationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListOperationsResponse | PlainMessage<ListOperationsResponse> | undefined, b: ListOperationsResponse | PlainMessage<ListOperationsResponse> | undefined): boolean {
    return proto3.util.equals(ListOperationsResponse, a, b);
  }
}

/**
 * The request message for [Operations.CancelOperation][google.longrunning.Operations.CancelOperation].
 *
 * @generated from message google.longrunning.CancelOperationRequest
 */
export class CancelOperationRequest extends Message<CancelOperationRequest> {
  /**
   * The name of the operation resource to be cancelled.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<CancelOperationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.CancelOperationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOperationRequest {
    return new CancelOperationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOperationRequest {
    return new CancelOperationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOperationRequest {
    return new CancelOperationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOperationRequest | PlainMessage<CancelOperationRequest> | undefined, b: CancelOperationRequest | PlainMessage<CancelOperationRequest> | undefined): boolean {
    return proto3.util.equals(CancelOperationRequest, a, b);
  }
}

/**
 * The request message for [Operations.DeleteOperation][google.longrunning.Operations.DeleteOperation].
 *
 * @generated from message google.longrunning.DeleteOperationRequest
 */
export class DeleteOperationRequest extends Message<DeleteOperationRequest> {
  /**
   * The name of the operation resource to be deleted.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteOperationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.DeleteOperationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteOperationRequest {
    return new DeleteOperationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteOperationRequest {
    return new DeleteOperationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteOperationRequest {
    return new DeleteOperationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteOperationRequest | PlainMessage<DeleteOperationRequest> | undefined, b: DeleteOperationRequest | PlainMessage<DeleteOperationRequest> | undefined): boolean {
    return proto3.util.equals(DeleteOperationRequest, a, b);
  }
}

/**
 * The request message for [Operations.WaitOperation][google.longrunning.Operations.WaitOperation].
 *
 * @generated from message google.longrunning.WaitOperationRequest
 */
export class WaitOperationRequest extends Message<WaitOperationRequest> {
  /**
   * The name of the operation resource to wait on.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The maximum duration to wait before timing out. If left blank, the wait
   * will be at most the time permitted by the underlying HTTP/RPC protocol.
   * If RPC context deadline is also specified, the shorter one will be used.
   *
   * @generated from field: google.protobuf.Duration timeout = 2;
   */
  timeout?: Duration;

  constructor(data?: PartialMessage<WaitOperationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.WaitOperationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timeout", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WaitOperationRequest {
    return new WaitOperationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WaitOperationRequest {
    return new WaitOperationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WaitOperationRequest {
    return new WaitOperationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WaitOperationRequest | PlainMessage<WaitOperationRequest> | undefined, b: WaitOperationRequest | PlainMessage<WaitOperationRequest> | undefined): boolean {
    return proto3.util.equals(WaitOperationRequest, a, b);
  }
}

/**
 * A message representing the message types used by a long-running operation.
 *
 * Example:
 *
 *   rpc LongRunningRecognize(LongRunningRecognizeRequest)
 *       returns (google.longrunning.Operation) {
 *     option (google.longrunning.operation_info) = {
 *       response_type: "LongRunningRecognizeResponse"
 *       metadata_type: "LongRunningRecognizeMetadata"
 *     };
 *   }
 *
 * @generated from message google.longrunning.OperationInfo
 */
export class OperationInfo extends Message<OperationInfo> {
  /**
   * Required. The message name of the primary return type for this
   * long-running operation.
   * This type will be used to deserialize the LRO's response.
   *
   * If the response is in a different package from the rpc, a fully-qualified
   * message name must be used (e.g. `google.protobuf.Struct`).
   *
   * Note: Altering this value constitutes a breaking change.
   *
   * @generated from field: string response_type = 1;
   */
  responseType = "";

  /**
   * Required. The message name of the metadata type for this long-running
   * operation.
   *
   * If the response is in a different package from the rpc, a fully-qualified
   * message name must be used (e.g. `google.protobuf.Struct`).
   *
   * Note: Altering this value constitutes a breaking change.
   *
   * @generated from field: string metadata_type = 2;
   */
  metadataType = "";

  constructor(data?: PartialMessage<OperationInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.longrunning.OperationInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OperationInfo {
    return new OperationInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OperationInfo {
    return new OperationInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OperationInfo {
    return new OperationInfo().fromJsonString(jsonString, options);
  }

  static equals(a: OperationInfo | PlainMessage<OperationInfo> | undefined, b: OperationInfo | PlainMessage<OperationInfo> | undefined): boolean {
    return proto3.util.equals(OperationInfo, a, b);
  }
}

/**
 * Additional information regarding long-running operations.
 * In particular, this specifies the types that are returned from
 * long-running operations.
 *
 * Required for methods that return `google.longrunning.Operation`; invalid
 * otherwise.
 *
 * @generated from extension: google.longrunning.OperationInfo operation_info = 1049;
 */
export const operation_info = proto3.makeExtension<MethodOptions, OperationInfo>(
  "google.longrunning.operation_info", 
  MethodOptions, 
  () => ({ no: 1049, kind: "message", T: OperationInfo }),
);

