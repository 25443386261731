import { appRevisionResourceName, formatDate } from '../../helpers/util';
import { useGetIncarnation } from '../../hooks/use_delivery_api';
import { IncarnationStateBadge } from './incarnation_state_badge';

interface IncarnationInfoProps {
  projectId: string;
  appId: string;
  revisionId: string;
}

export function IncarnationInfo(props: IncarnationInfoProps) {
  const name = appRevisionResourceName(
    props.projectId,
    props.appId,
    props.revisionId,
  );
  const { data } = useGetIncarnation({ name: name });
  const incarnation = data;

  return (
    <>
      <div className="px-6 pt-8">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Incarnation basics
        </h3>
      </div>
      <div className="mt-3 max-w-xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <IncarnationStateBadge state={incarnation.state} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(incarnation.createTime.toDate())}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(incarnation.updateTime.toDate())}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
