import { useListSSHKeys } from '../../hooks/use_delivery_api';

interface SSHKeyChooserProps {
  projectId: string;
  register: any;
}

export function SSHKeyChooser(props: SSHKeyChooserProps) {
  const { data } = useListSSHKeys({
    parent: `projects/${props.projectId}`,
    pageSize: 100,
    pageToken: '',
  });

  return (
    <select
      {...props.register('sshKey')}
      id="sshKey"
      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      defaultValue={data.sshKeys[0].name.split('/')[3]}
    >
      {data.sshKeys.map((key) => <option>{key.name.split('/')[3]}</option>)}
    </select>
  );
}
