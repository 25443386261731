import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';

import {
  ignoreDifferenceTypeToProto,
  projectResourceName,
} from '../../helpers/util';
import { createIgnoreDifference } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { createToast } from '../core/toast';

interface IgnoreDifferenceCreateProps {
  route: Route<typeof routes.ignoreDifferenceCreate>;
}

interface IgnoreDifferenceCreateFormData {
  name: string;
  ignoreType: string;
  ignoreValue: string;
  objectGroup: string;
  objectKind: string;
}

export function IgnoreDifferenceCreate(props: IgnoreDifferenceCreateProps) {
  const { route } = props;
  const { register, handleSubmit } = useForm<IgnoreDifferenceCreateFormData>();

  const parent = projectResourceName(route.params.projectId);

  const onSubmit = async (data: IgnoreDifferenceCreateFormData) => {
    try {
      await createIgnoreDifference({
        parent: parent,
        ignoreDifference: {
          name: '',
          state: 0,
          objectGroup: data.objectGroup,
          objectKind: data.objectKind,
          type: ignoreDifferenceTypeToProto(data.ignoreType),
          value: data.ignoreValue,
        },
        ignoreDifferenceId: data.name,
      });
      createToast(`Ignore difference "${data.name}" created.`);
      routes.ignoreDifferenceDetail({
        projectId: route.params.projectId,
        ignoreDifferenceId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating ignore difference: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'Ignore Differences',
      link: routes.ignoreDifferenceList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            New ignore difference
          </h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        An ignore difference is a way of specifying fields in a resource that
        should be ignored during the diff process.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  {...register('name')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="objectGroup"
                className="block text-sm font-medium text-gray-700"
              >
                Object group
              </label>
              <div className="mt-1">
                <input
                  {...register('objectGroup')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="objectKind"
                className="block text-sm font-medium text-gray-700"
              >
                Object kind
              </label>
              <div className="mt-1">
                <input
                  {...register('objectKind')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="ignoreType"
                className="block text-sm font-medium text-gray-700"
              >
                Type
              </label>
              <div className="mt-1">
                <select
                  {...register('ignoreType')}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option>JsonPointer</option>
                  <option>JqPathExpression</option>
                  <option>ManagedFieldsManager</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="ignoreValue"
                className="block text-sm font-medium text-gray-700"
              >
                Value
              </label>
              <div className="mt-1">
                <input
                  {...register('ignoreValue')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.ignoreDifferenceList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="ml-3 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
