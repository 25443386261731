import { Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';

import { projectResourceName } from '../../helpers/util';
import { createApplication } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { createToast } from '../core/toast';
import { ClusterChooser } from './cluster_chooser';
import { GitRepoChooser } from './git_repo_chooser';

interface ApplicationCreateProps {
  route: Route<typeof routes.applicationCreate>;
}

interface ApplicationCreateFormData {
  name: string;
  gitSourceEnabled: boolean;
  gitRepository: string;
  gitRevision: string;
  gitPath: string;
  cluster: string;
  namespace: string;
}

export function ApplicationCreate(props: ApplicationCreateProps) {
  const { route } = props;
  const { register, watch, handleSubmit } = useForm<
    ApplicationCreateFormData
  >();
  const parent = projectResourceName(route.params.projectId);

  const onSubmit = async (data: ApplicationCreateFormData) => {
    try {
      await createApplication({
        parent: parent,
        application: {
          name: '',
          syncState: 0,
          healthState: 0,
          assetCount: 0,
          source: {
            git: {
              repository: data.gitRepository
                ? `projects/${route.params.projectId}/gitRepositories/${data.gitRepository}`
                : '',
              revision: data.gitRevision,
              path: data.gitPath,
              revisionCommit: '',
              pathCommit: '',
            },
          },
          target: {
            cluster: data.cluster
              ? `projects/${route.params.projectId}/clusters/${data.cluster}`
              : '',
            namespace: data.namespace,
          },
          desiredIncarnation: '',
          targetIncarnation: '',
        },
        applicationId: data.name,
      });
      createToast(`Application "${data.name}" created.`);
      routes.applicationDetail({
        projectId: route.params.projectId,
        applicationId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating application: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'Applications',
      link: routes.applicationList(
        {
          projectId: route.params.projectId,
        },
      ).link,
      current: true,
    },
  ];

  const gitSourceEnabled = watch('gitSourceEnabled');
  console.log(gitSourceEnabled);

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            New application
          </h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        An application consists of a set of objects that are versioned together
        and deployed to a cluster.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Application name
              </label>
              <div className="mt-1">
                <input
                  {...register('name')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Sources
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                These define where your objects will be synchronized from. If
                multiple sources are specified, their files are merged together.
              </p>
            </div>
            <fieldset className="mt-4">
              <legend className="sr-only">Sources</legend>
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      {...register('gitSourceEnabled')}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="gitSourceEnabled"
                      className="font-medium text-gray-700"
                    >
                      Enable Git repository source
                    </label>
                    <p
                      id="gitSourceEnabled-description"
                      className="text-gray-500"
                    >
                      Synchronize objects rendered from files in git
                    </p>
                  </div>
                </div>
                {gitSourceEnabled && (
                  <div className="ml-7">
                    <div className="space-y-6">
                      <div>
                        <label
                          htmlFor="gitRepository"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Git repository
                        </label>
                        <ErrorBoundary>
                          <Suspense fallback={<Spinner />}>
                            <GitRepoChooser
                              projectId={route.params.projectId}
                              register={register}
                            />
                          </Suspense>
                        </ErrorBoundary>
                      </div>
                      <div>
                        <label
                          htmlFor="gitRevision"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Git revision
                        </label>
                        <div className="mt-1">
                          <input
                            {...register('gitRevision')}
                            type="text"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="gitPath"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Git path
                        </label>
                        <div className="mt-1">
                          <input
                            {...register('gitPath')}
                            type="text"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </fieldset>
          </div>
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Target
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                This defines where your objects will be deployed to.
              </p>
            </div>
            <div className="mt-6">
              <div>
                <label
                  htmlFor="cluster"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cluster
                </label>
                <ErrorBoundary>
                  <Suspense fallback={<Spinner />}>
                    <ClusterChooser
                      projectId={route.params.projectId}
                      register={register}
                    />
                  </Suspense>
                </ErrorBoundary>
              </div>
            </div>
            <div className="mt-6">
              <div>
                <label
                  htmlFor="namespace"
                  className="block text-sm font-medium text-gray-700"
                >
                  Namespace
                </label>
                <div className="mt-1">
                  <input
                    {...register('namespace')}
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.applicationList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="ml-3 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
