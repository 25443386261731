import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  TransitionChild,
} from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  ChartBarIcon,
  Cog8ToothIcon,
  KeyIcon,
  RectangleGroupIcon,
  ServerStackIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Suspense, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route } from 'type-route';

import { groups, routes } from '../../router';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { ProjectMenu } from './project_menu';
//import { ProjectNavigation } from './project_navigation';
//import { UserMenu } from './user_menu';
import { classNames } from '../../helpers/util';

export interface SidebarLayoutProps {
  route: Route<typeof groups.project>;
  children: JSX.Element;
}

export function SidebarLayout(props: SidebarLayoutProps) {
  const { route, children } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dashboardRoute = routes.projectDetail({
    projectId: route.params.projectId,
  });
  const applicationsRoute = routes.applicationList({
    projectId: route.params.projectId,
  });
  const clustersRoute = routes.clusterList({
    projectId: route.params.projectId,
  });
  const gitRepositoriesRoute = routes.gitRepositoryList({
    projectId: route.params.projectId,
  });
  const sshKeysRoute = routes.sshKeyList({ projectId: route.params.projectId });
  const sshKnownHostsRoute = routes.sshKnownHostList({
    projectId: route.params.projectId,
  });
  const ignoreDifferencesRoute = routes.ignoreDifferenceList({
    projectId: route.params.projectId,
  });
  const computeClustersRoute = routes.computeClusterList({
    projectId: route.params.projectId,
  });
  const computeMachinesRoute = routes.computeMachineList({
    projectId: route.params.projectId,
  });
  const computeOperationsRoute = routes.computeOperationList({
    projectId: route.params.projectId,
  });
  const roleBindingsRoute = routes.roleBindingList({
    projectId: route.params.projectId,
  });
  const oidcProvidersRoute = routes.oidcProviderList({
    projectId: route.params.projectId,
  });
  const serviceAccountsRoute = routes.serviceAccountList({
    projectId: route.params.projectId,
  });
  const settingsRoute = routes.projectSettings({
    projectId: route.params.projectId,
  });

  const navigation = [
    {
      name: 'Dashboard',
      icon: ChartBarIcon,
      route: dashboardRoute,
      current: dashboardRoute.name === route.name,
      defaultOpen: false,
    },
    {
      name: 'Delivery',
      icon: RectangleGroupIcon,
      current: false,
      defaultOpen: groups.delivery.has(route),
      children: [
        {
          name: 'Applications',
          route: applicationsRoute,
          current: groups.application.has(route),
          defaultOpen: false,
        },
        {
          name: 'Clusters',
          route: clustersRoute,
          current: groups.cluster.has(route),
          defaultOpen: false,
        },
        {
          name: 'Git repositories',
          route: gitRepositoriesRoute,
          current: groups.gitRepository.has(route),
          defaultOpen: false,
        },
        {
          name: 'SSH keys',
          route: sshKeysRoute,
          current: groups.sshKey.has(route),
          defaultOpen: false,
        },
        {
          name: 'SSH known hosts',
          route: sshKnownHostsRoute,
          current: groups.sshKnownHost.has(route),
          defaultOpen: false,
        },
        {
          name: 'Ignore differences',
          route: ignoreDifferencesRoute,
          current: groups.ignoreDifference.has(route),
          defaultOpen: false,
        },
      ],
    },
    {
      name: 'Compute',
      icon: ServerStackIcon,
      current: false,
      defaultOpen: groups.compute.has(route),
      children: [
        {
          name: 'Clusters',
          route: computeClustersRoute,
          current: groups.computeCluster.has(route),
          defaultOpen: false,
        },
        {
          name: 'Machines',
          route: computeMachinesRoute,
          current: groups.computeMachine.has(route),
          defaultOpen: false,
        },
        {
          name: 'Operations',
          route: computeOperationsRoute,
          current: groups.computeOperation.has(route),
          defaultOpen: false,
        },
      ],
    },
    {
      name: 'IAM',
      icon: KeyIcon,
      current: false,
      defaultOpen: groups.iam.has(route),
      children: [
        {
          name: 'Role bindings',
          route: roleBindingsRoute,
          current: groups.iamRoleBinding.has(route),
          defaultOpen: false,
        },
        {
          name: 'OIDC providers',
          route: oidcProvidersRoute,
          current: groups.iamOidcProvider.has(route),
          defaultOpen: false,
        },
        {
          name: 'Service accounts',
          route: serviceAccountsRoute,
          current: groups.iamServiceAccount.has(route),
          defaultOpen: false,
        },
      ],
    },
    {
      name: 'Settings',
      icon: Cog8ToothIcon,
      route: settingsRoute,
      current: settingsRoute.name === route.name,
      defaultOpen: false,
    },
  ];

  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-800/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-800 px-6 pb-2 ring-1 ring-white/10">
                <div className="flex flex-col -mx-4 mt-2">
                  <ErrorBoundary>
                    <Suspense fallback={<Spinner />}>
                      <ProjectMenu projectId={route.params.projectId} />
                    </Suspense>
                  </ErrorBoundary>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            {!item.children ?
                              (
                                <a
                                  {...item.route.link}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-900'
                                      : 'hover:bg-gray-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-300',
                                  )}
                                >
                                  <item.icon
                                    aria-hidden="true"
                                    className="h-6 w-6 shrink-0 text-gray-400" />
                                  {item.name}
                                </a>
                              ) :
                              (
                                <Disclosure
                                  as="div"
                                  defaultOpen={item.defaultOpen}>
                                  <DisclosureButton
                                    className={classNames(
                                      item.current
                                        ? 'bg-gray-900'
                                        : 'hover:bg-gray-700',
                                      'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-semibold text-gray-300',
                                    )}
                                  >
                                    <item.icon
                                      aria-hidden="true"
                                      className="h-6 w-6 shrink-0 text-gray-400" />
                                    {item.name}
                                    <ChevronRightIcon
                                      aria-hidden="true"
                                      className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                    />
                                  </DisclosureButton>
                                  <DisclosurePanel
                                    as="ul"
                                    className="mt-1 px-2">
                                    {item.children.map((subItem) => (
                                      <li key={subItem.name}>
                                        {/* 44px */}
                                        <DisclosureButton
                                          as="a"
                                          {...subItem.route.link}
                                          className={classNames(
                                            subItem.current
                                              ? 'bg-gray-900'
                                              : 'hover:bg-gray-700',
                                            'block rounded-md py-2 pl-9 pr-2 text-sm/6 text-gray-300',
                                          )}
                                        >
                                          {subItem.name}
                                        </DisclosureButton>
                                      </li>
                                    ))}
                                  </DisclosurePanel>
                                </Disclosure>
                              )}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-800 px-6">
            <div className="flex flex-col -mx-4 mt-2">
              <ErrorBoundary>
                <Suspense fallback={<Spinner />}>
                  <ProjectMenu projectId={route.params.projectId} />
                </Suspense>
              </ErrorBoundary>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        {!item.children ?
                          (
                            <a
                              {...item.route.link}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-900'
                                  : 'hover:bg-gray-700',
                                'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-300',
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className="h-6 w-6 shrink-0 text-gray-400" />
                              {item.name}
                            </a>
                          ) :
                          (
                            <Disclosure as="div" defaultOpen={item.defaultOpen}>
                              <DisclosureButton
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-900'
                                    : 'hover:bg-gray-700',
                                  'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-semibold text-gray-300',
                                )}
                              >
                                <item.icon
                                  aria-hidden="true"
                                  className="h-6 w-6 shrink-0 text-gray-400" />
                                {item.name}
                                <ChevronRightIcon
                                  aria-hidden="true"
                                  className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                />
                              </DisclosureButton>
                              <DisclosurePanel as="ul" className="mt-1 px-2">
                                {item.children.map((subItem) => (
                                  <li key={subItem.name}>
                                    {/* 44px */}
                                    <DisclosureButton
                                      as="a"
                                      {...subItem.route.link}
                                      className={classNames(
                                        subItem.current
                                          ? 'bg-gray-900'
                                          : 'hover:bg-gray-700',
                                        'block rounded-md py-2 pl-9 pr-2 text-sm/6 text-gray-300',
                                      )}
                                    >
                                      {subItem.name}
                                    </DisclosureButton>
                                  </li>
                                ))}
                              </DisclosurePanel>
                            </Disclosure>
                          )}
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href={routes.logout().link.href}
                    className="flex items-center gap-x-4 px-6 py-3 text-sm/6 font-semibold text-white hover:bg-gray-800"
                  >
                    <ArrowRightStartOnRectangleIcon
                      aria-hidden="true"
                      className="h-8 w-8 shrink-0 text-gray-400"
                    />
                    <span className="sr-only">Sign out</span>
                    <span aria-hidden="true">Sign out</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-800 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden">
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
          <div className="flex-1 text-sm/6 font-semibold text-white">
            Dashboard
          </div>
          <a href="#">
            <span className="sr-only">Your profile</span>
            <img
              alt=""
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              className="h-8 w-8 rounded-full bg-gray-800"
            />
          </a>
        </div>

        <main className="py-4 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
      <Toaster position="bottom-center" containerStyle={{ bottom: 24 }} />
    </>
  );
}
