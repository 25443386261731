import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { RolloutInfo } from './rollout_info';
import { RolloutStepTable } from './rollout_step_table';

interface RolloutDetailProps {
  route: Route<typeof routes.rolloutDetail>;
}

export function RolloutDetail(props: RolloutDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Applications',
      link: routes.applicationList({
        projectId: route.params.projectId,
      }).link,
      current: false,
    },
    {
      name: route.params.applicationId,
      link: routes.applicationDetail({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: false,
    },
    {
      name: 'Rollouts',
      link: routes.rolloutList({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0">
          <h2 className="text-2xl font-medium text-gray-900">
            {route.params.rolloutId}
          </h2>
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <RolloutInfo
            projectId={route.params.projectId}
            appId={route.params.applicationId}
            rolloutId={route.params.rolloutId}
          />
        </Suspense>
      </ErrorBoundary>
      <div className="mt-8">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Steps</h3>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <RolloutStepTable
            projectId={route.params.projectId}
            appId={route.params.applicationId}
            rolloutId={route.params.rolloutId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
