import {
  formatDate,
  ignoreDifferenceTypeToString,
  projectResourceName,
} from '../../helpers/util';
import { useListIgnoreDifferences } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { IgnoreDifferenceKebab } from './ignore_difference_kebab';
import { IgnoreDifferenceStateBadge } from './ignore_difference_state_badge';

interface IgnoreDifferenceTableProps {
  projectId: string;
}

export function IgnoreDifferenceTable(props: IgnoreDifferenceTableProps) {
  const parent = projectResourceName(props.projectId);
  const { data, mutate } = useListIgnoreDifferences({
    parent: parent,
    pageSize: 15,
    pageToken: '',
  });
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Object group
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Object kind
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Type
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Value
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.ignoreDifferences.length == 0 && (
                <tr>
                  <td
                    colSpan={8}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No ignore differences to display
                  </td>
                </tr>
              )}
              {data.ignoreDifferences.map((ignore) => (
                <tr key={ignore.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.ignoreDifferenceDetail({
                        projectId: props.projectId,
                        ignoreDifferenceId: ignore.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {ignore.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <IgnoreDifferenceStateBadge state={ignore.state} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {ignore.objectGroup === '' ? '\u2014' : ignore.objectGroup}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {ignore.objectKind === '' ? '\u2014' : ignore.objectKind}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                      {ignoreDifferenceTypeToString(ignore.type)}
                    </span>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {ignore.value}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {formatDate(ignore.createTime.toDate())}
                  </td>
                  <td className="w-full border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <IgnoreDifferenceKebab
                      projectId={props.projectId}
                      ignoreDifferenceId={ignore.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.ignoreDifferences.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.ignoreDifferences.length}
              startIndex={data.startIndex}
              previousPageLink={routes.ignoreDifferenceList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.ignoreDifferenceList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
