import { formatDate, sshKeyResourceName } from '../../helpers/util';
import { useGetSSHKey } from '../../hooks/use_delivery_api';
import { SSHKeyStateBadge } from './ssh_key_state_badge';

interface SSHKeyInfoProps {
  projectId: string;
  keyId: string;
}

export function SSHKeyInfo(props: SSHKeyInfoProps) {
  const name = sshKeyResourceName(props.projectId, props.keyId);
  const { data } = useGetSSHKey({ name: name });
  const key = data;
  const authorizedKey =
    `ssh-${key.algorithm} ${key.publicKey} ${props.keyId}.project-${props.projectId}@api.annealing.dev`;

  return (
    <>
      <div className="mt-4 max-w-3xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <SSHKeyStateBadge state={key.state} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Algorithm</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {key.algorithm}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Fingerprint</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {key.fingerprintSha256}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(key.createTime.toDate())}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Authorized key
        </h3>
      </div>
      <div className="mt-3 overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <div className="overflow-x-auto whitespace-nowrap font-mono text-sm text-gray-900">
            <div>{authorizedKey}</div>
          </div>
        </div>
      </div>
    </>
  );
}
