import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { BranchTable } from './branch_table';
import { GitRepoHeading } from './git_repo_heading';
import { TagTable } from './tag_table';

interface GitRepoDetailProps {
  route: Route<typeof routes.gitRepositoryDetail>;
}

export function GitRepoDetail(props: GitRepoDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Git repositories',
      link: routes.gitRepositoryList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <GitRepoHeading
            projectId={route.params.projectId}
            repoId={route.params.gitRepositoryId} />
        </Suspense>
      </ErrorBoundary>
      {route.name === 'gitRepositoryDetail' && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <BranchTable
              projectId={route.params.projectId}
              repoId={route.params.gitRepositoryId} />
          </Suspense>
        </ErrorBoundary>
      )}
      {false && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <TagTable
              projectId={route.params.projectId}
              repoId={route.params.gitRepositoryId} />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  );
}
