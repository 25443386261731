import { Suspense } from 'react';

import { routes } from '../../router';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { UserInfo } from './user_info';

export function User() {
  return (
    <div className="p-5">
      <nav>
        <a
          {...routes.home().link}
          className="flex items-center text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700"
        >
          <svg
            className="mr-1 -ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          Home
        </a>
      </nav>
      <main className="mx-auto max-w-xl px-4 pt-10 pb-12 lg:pb-16">
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h1 className="text-2xl font-semibold text-gray-900">
              User settings
            </h1>
          </div>
        </div>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <UserInfo />
          </Suspense>
        </ErrorBoundary>
      </main>
    </div>
  );
}
