import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';

import { classNames, machineResourceName } from '../../helpers/util';
import { deleteMachine } from '../../hooks/use_compute_api';
import { routes } from '../../router';
import { DeleteModal } from '../core/delete_modal';
import { createToast } from '../core/toast';

interface MachineKebabProps {
  projectId: string;
  machineId: string;
  mutate: any;
}

export function MachineKebab(props: MachineKebabProps) {
  const [open, setOpen] = useState(false);

  const onSubmit = async () => {
    try {
      await deleteMachine({
        name: machineResourceName(props.projectId, props.machineId),
        validateOnly: false,
        allowMissing: true,
        etag: '',
      });
      setOpen(false);
      props.mutate();
      createToast(`Machine "${props.machineId}" detached.`);
      routes.computeMachineList({
        projectId: props.projectId,
      }).push();
    } catch (err) {
      createToast(`Error detaching machine: ${err}`);
    }
  };

  return (
    <>
      <Menu as="div" className="relative ml-3 inline-block text-left">
        <div>
          <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-600">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon
              className="-my-0.5 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute top-6 right-0 z-10 mx-3 mt-1 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    onClick={() => setOpen(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group block flex w-full items-center px-4 py-2 text-left text-sm',
                    )}
                  >
                    <TrashIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Detach
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <DeleteModal
        title="Detach machine"
        description={`Are you sure you want to detach ${props.machineId}? This action cannot be undone.`}
        action="Detach"
        onSubmit={onSubmit}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
