import {
  formatDate,
  ignoreDifferenceResourceName,
  ignoreDifferenceTypeToString,
} from '../../helpers/util';
import { useGetIgnoreDifference } from '../../hooks/use_delivery_api';
import { IgnoreDifferenceStateBadge } from './ignore_difference_state_badge';

interface IgnoreDifferenceInfoProps {
  projectId: string;
  ignoreDifferenceId: string;
}

export function IgnoreDifferenceInfo(props: IgnoreDifferenceInfoProps) {
  const name = ignoreDifferenceResourceName(
    props.projectId,
    props.ignoreDifferenceId,
  );
  const { data } = useGetIgnoreDifference({ name: name });
  const ignore = data;

  return (
    <>
      <div className="mt-4 max-w-5xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <IgnoreDifferenceStateBadge state={ignore.state} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Object group
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {ignore.objectGroup.length === 0
                  ? '\u2014'
                  : ignore.objectGroup}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Object kind</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {ignore.objectKind.length === 0 ? '\u2014' : ignore.objectKind}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                  {ignoreDifferenceTypeToString(ignore.type)}
                </span>
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Value</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {ignore.value}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(ignore.createTime.toDate())}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(ignore.updateTime.toDate())}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
