import { Suspense } from 'react';
import { type Route } from 'type-route';

import { groups, routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { Tabs } from '../core/tabs';
import { AssetInfo } from './asset_info';
import { AssetManifest } from './asset_manifest';

interface AssetDetailProps {
  route: Route<typeof groups.assetDetail>;
}

export function AssetDetail(props: AssetDetailProps) {
  const { route } = props;
  const parts = route.params.assetId.split('~');
  const clusterId = parts[0];
  const apiGroup = parts[1];
  const apiKind = parts[2];
  const namespaceName = parts[3];
  const objectName = parts[4];
  const breadcrumbs = [
    {
      name: 'Applications',
      link: routes.applicationList({
        projectId: route.params.projectId,
      }).link,
      current: false,
    },
    {
      name: route.params.applicationId,
      link: routes.applicationDetail({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: false,
    },
    {
      name: 'Assets',
      link: routes.assetList({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: true,
    },
  ];

  const tabs = [
    {
      name: 'Details',
      link: routes.assetDetail({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
        assetId: route.params.assetId,
      }).link,
      current: route.name === 'assetDetail',
    },
    {
      name: 'YAML',
      link: routes.assetYaml({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
        assetId: route.params.assetId,
      }).link,
      current: route.name === 'assetYaml',
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="my-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <div className="flex flex-wrap items-baseline">
            <h2 className="text-2xl font-medium text-gray-900">
              {namespaceName === '' && <span>{objectName}</span>}
              {namespaceName !== '' && <span>{objectName}</span>}
            </h2>
          </div>
        </div>
      </div>
      <div>
        <Tabs tabs={tabs} />
        {route.name === 'assetDetail' && (
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <AssetInfo
                projectId={route.params.projectId}
                appId={route.params.applicationId}
                clusterId={clusterId}
                apiGroup={apiGroup}
                apiKind={apiKind}
                namespaceName={namespaceName}
                objectName={objectName}
              />
            </Suspense>
          </ErrorBoundary>
        )}
        {route.name === 'assetYaml' && (
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <AssetManifest
                projectId={route.params.projectId}
                appId={route.params.applicationId}
                clusterId={clusterId}
                apiGroup={apiGroup}
                apiKind={apiKind}
                namespaceName={namespaceName}
                objectName={objectName}
              />
            </Suspense>
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
}
