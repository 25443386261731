import { Rollout } from '../../../proto/annealing/delivery/v1/resources_pb';
import { rolloutPercentage, rolloutStateToString } from '../../helpers/util';

interface RolloutProgressBarProps {
  rollout: Rollout;
}

export function RolloutProgressBar(props: RolloutProgressBarProps) {
  const color = rolloutStateToString(props.rollout.state) === 'Running'
    ? 'bg-blue-500'
    : 'bg-gray-400';
  return (
    <span className="flex items-center text-sm text-gray-500">
      <div className="flex-shrink-0">
        <div className="h-1.5 w-20 rounded-full bg-gray-200">
          <div
            className={`${color} h-1.5 rounded-full`}
            style={{
              width: `${rolloutPercentage(props.rollout)}` + '%',
            }}
          />
        </div>
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <span>{rolloutPercentage(props.rollout)}%</span>
      </div>
    </span>
  );
}
