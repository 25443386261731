import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import useSWR from 'swr';

import { Operations } from '../../proto/google/longrunning/operations_connect';

const refreshInterval = 2000;
const transport = createGrpcWebTransport({ baseUrl: '' });

const client = createPromiseClient(Operations, transport);
const opts = { refreshInterval: refreshInterval, suspense: true };

async function listOperations(req: any) {
  return await client.listOperations(req);
}

async function getOperation(req: any) {
  return await client.getOperation(req);
}

export function useListOperations(req: any) {
  return useSWR(
    ['google.longrunning.OperationsService/ListOperations', req],
    ([_m, req]) => listOperations(req),
    opts,
  );
}

export function useGetOperation(req: any) {
  return useSWR(
    ['google.longrunning.OperationsService/GetOperation', req],
    ([_m, req]) => getOperation(req),
    opts,
  );
}
