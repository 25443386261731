import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { ServiceAccountDeleteModal } from './service_account_delete_modal';
import { ServiceAccountInfo } from './service_account_info';

interface ServiceAccountDetailProps {
  route: Route<typeof routes.serviceAccountDetail>;
}

export function ServiceAccountDetail(props: ServiceAccountDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Service accounts',
      link: routes.serviceAccountList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="my-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            {route.params.serviceAccountId}
          </h1>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
          <ServiceAccountDeleteModal
            projectId={route.params.projectId}
            accountId={route.params.serviceAccountId}
          />
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ServiceAccountInfo
            projectId={route.params.projectId}
            accountId={route.params.serviceAccountId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
