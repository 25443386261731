import { useListGitRepositories } from '../../hooks/use_delivery_api';

interface GitRepoChooserProps {
  projectId: string;
  register: any;
}

export function GitRepoChooser(props: GitRepoChooserProps) {
  const { data } = useListGitRepositories({
    parent: `projects/${props.projectId}`,
    pageSize: 100,
    pageToken: '',
  });

  return (
    <select
      {...props.register('gitRepository')}
      id="gitRepository"
      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      defaultValue={data.gitRepositories[0].name.split('/')[3]}
    >
      {data.gitRepositories.map((repo) => (
        <option>{repo.name.split('/')[3]}</option>
      ))}
    </select>
  );
}
