// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file annealing/iam/v1/resources.proto (package annealing.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { IdentityProviderState, OidcAccessType, Role, RoleBindingState, ServiceAccountState } from "./enums_pb.js";

/**
 * @generated from message annealing.iam.v1.IdentityProvider
 */
export class IdentityProvider extends Message<IdentityProvider> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: annealing.iam.v1.IdentityProviderState state = 2;
   */
  state = IdentityProviderState.UNSPECIFIED;

  /**
   * @generated from field: annealing.iam.v1.Oidc oidc = 3;
   */
  oidc?: Oidc;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 5;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<IdentityProvider>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.IdentityProvider";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(IdentityProviderState) },
    { no: 3, name: "oidc", kind: "message", T: Oidc },
    { no: 4, name: "create_time", kind: "message", T: Timestamp },
    { no: 5, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IdentityProvider {
    return new IdentityProvider().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IdentityProvider {
    return new IdentityProvider().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IdentityProvider {
    return new IdentityProvider().fromJsonString(jsonString, options);
  }

  static equals(a: IdentityProvider | PlainMessage<IdentityProvider> | undefined, b: IdentityProvider | PlainMessage<IdentityProvider> | undefined): boolean {
    return proto3.util.equals(IdentityProvider, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.Oidc
 */
export class Oidc extends Message<Oidc> {
  /**
   * @generated from field: string issuer_uri = 1;
   */
  issuerUri = "";

  /**
   * @generated from field: annealing.iam.v1.OidcAccessType access_type = 2;
   */
  accessType = OidcAccessType.UNSPECIFIED;

  /**
   * @generated from field: string jwks = 3;
   */
  jwks = "";

  constructor(data?: PartialMessage<Oidc>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.Oidc";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "issuer_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_type", kind: "enum", T: proto3.getEnumType(OidcAccessType) },
    { no: 3, name: "jwks", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Oidc {
    return new Oidc().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Oidc {
    return new Oidc().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Oidc {
    return new Oidc().fromJsonString(jsonString, options);
  }

  static equals(a: Oidc | PlainMessage<Oidc> | undefined, b: Oidc | PlainMessage<Oidc> | undefined): boolean {
    return proto3.util.equals(Oidc, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.RoleBinding
 */
export class RoleBinding extends Message<RoleBinding> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: annealing.iam.v1.RoleBindingState state = 2;
   */
  state = RoleBindingState.UNSPECIFIED;

  /**
   * @generated from field: string member = 3;
   */
  member = "";

  /**
   * @generated from field: annealing.iam.v1.Role role = 4;
   */
  role = Role.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 5;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 6;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<RoleBinding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.RoleBinding";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(RoleBindingState) },
    { no: 3, name: "member", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 5, name: "create_time", kind: "message", T: Timestamp },
    { no: 6, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleBinding {
    return new RoleBinding().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleBinding {
    return new RoleBinding().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleBinding {
    return new RoleBinding().fromJsonString(jsonString, options);
  }

  static equals(a: RoleBinding | PlainMessage<RoleBinding> | undefined, b: RoleBinding | PlainMessage<RoleBinding> | undefined): boolean {
    return proto3.util.equals(RoleBinding, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.ServiceAccount
 */
export class ServiceAccount extends Message<ServiceAccount> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: annealing.iam.v1.ServiceAccountState state = 2;
   */
  state = ServiceAccountState.UNSPECIFIED;

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string jwks = 4;
   */
  jwks = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 5;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 6;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<ServiceAccount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.ServiceAccount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(ServiceAccountState) },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "jwks", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "create_time", kind: "message", T: Timestamp },
    { no: 6, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServiceAccount {
    return new ServiceAccount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServiceAccount {
    return new ServiceAccount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServiceAccount {
    return new ServiceAccount().fromJsonString(jsonString, options);
  }

  static equals(a: ServiceAccount | PlainMessage<ServiceAccount> | undefined, b: ServiceAccount | PlainMessage<ServiceAccount> | undefined): boolean {
    return proto3.util.equals(ServiceAccount, a, b);
  }
}

