// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file annealing/organization/v1/service.proto (package annealing.organization.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateProjectRequest, DeleteProjectRequest, GetProjectRequest, ListProjectsRequest, ListProjectsResponse } from "./service_pb.js";
import { Project } from "./resources_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service annealing.organization.v1.OrganizationService
 */
export const OrganizationService = {
  typeName: "annealing.organization.v1.OrganizationService",
  methods: {
    /**
     * @generated from rpc annealing.organization.v1.OrganizationService.GetProject
     */
    getProject: {
      name: "GetProject",
      I: GetProjectRequest,
      O: Project,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.organization.v1.OrganizationService.ListProjects
     */
    listProjects: {
      name: "ListProjects",
      I: ListProjectsRequest,
      O: ListProjectsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.organization.v1.OrganizationService.CreateProject
     */
    createProject: {
      name: "CreateProject",
      I: CreateProjectRequest,
      O: Project,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.organization.v1.OrganizationService.DeleteProject
     */
    deleteProject: {
      name: "DeleteProject",
      I: DeleteProjectRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

