// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file annealing/sts/v1/resources.proto (package annealing.sts.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message annealing.sts.v1.Session
 */
export class Session extends Message<Session> {
  /**
   * The name of the session.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Output only. The token for the session.
   *
   * @generated from field: string token = 2;
   */
  token = "";

  /**
   * Output only. The time the session was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 3;
   */
  createTime?: Timestamp;

  /**
   * Output only. The time the session was updated.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 4;
   */
  updateTime?: Timestamp;

  /**
   * Output only. The time the session expired.
   *
   * @generated from field: google.protobuf.Timestamp expire_time = 5;
   */
  expireTime?: Timestamp;

  constructor(data?: PartialMessage<Session>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.sts.v1.Session";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
    { no: 4, name: "update_time", kind: "message", T: Timestamp },
    { no: 5, name: "expire_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Session {
    return new Session().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Session {
    return new Session().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Session {
    return new Session().fromJsonString(jsonString, options);
  }

  static equals(a: Session | PlainMessage<Session> | undefined, b: Session | PlainMessage<Session> | undefined): boolean {
    return proto3.util.equals(Session, a, b);
  }
}

/**
 * @generated from message annealing.sts.v1.UserInfo
 */
export class UserInfo extends Message<UserInfo> {
  /**
   * The email address of the user or service account.
   *
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * The name of the user.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * The avatar of the user.
   *
   * @generated from field: string picture = 3;
   */
  picture = "";

  constructor(data?: PartialMessage<UserInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.sts.v1.UserInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInfo {
    return new UserInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJsonString(jsonString, options);
  }

  static equals(a: UserInfo | PlainMessage<UserInfo> | undefined, b: UserInfo | PlainMessage<UserInfo> | undefined): boolean {
    return proto3.util.equals(UserInfo, a, b);
  }
}

