import { formatDate, roleToString } from '../../helpers/util';
import { useListRoleBindings } from '../../hooks/use_iam_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { RoleBindingKebab } from './role_binding_kebab';
import { RoleBindingStateBadge } from './role_binding_state_badge';

interface RoleBindingTableProps {
  projectId: string;
}

export function RoleBindingTable(props: RoleBindingTableProps) {
  const { data, mutate } = useListRoleBindings({
    parent: `projects/${props.projectId}`,
    pageSize: 15,
    pageToken: '',
  });
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Member
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Role
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.roleBindings.map((binding) => (
                <tr key={binding.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.roleBindingDetail({
                        projectId: props.projectId,
                        roleBindingId: binding.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {binding.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <RoleBindingStateBadge state={binding.state} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {binding.member}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                      {roleToString(binding.role)}
                    </span>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {formatDate(binding.createTime.toDate())}
                  </td>
                  <td className="w-full whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <RoleBindingKebab
                      projectId={props.projectId}
                      bindingId={binding.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.roleBindings.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.roleBindings.length}
              startIndex={data.startIndex}
              previousPageLink={routes.roleBindingList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.roleBindingList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
