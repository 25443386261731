import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface PaginationFooterProps {
  totalSize: number;
  pageSize: number;
  startIndex: number;
  previousPageLink: string;
  nextPageLink: string;
}

export function PaginationFooter(props: PaginationFooterProps) {
  return (
    <nav
      className="mb-3 flex items-center justify-between bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <span className="font-medium">{props.startIndex + 1}</span>
          {'\u2014'}
          <span className="font-medium">
            {props.startIndex + props.pageSize}
          </span>{' '}
          of <span className="font-medium">{props.totalSize}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        {props.startIndex <= 0 && (
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 opacity-50 shadow-sm hover:bg-gray-50"
            disabled
          >
            <ChevronLeftIcon className="-ml-1.5 h-5 w-5" />
            Previous
          </button>
        )}
        {props.startIndex > 0 && (
          <a
            href={props.previousPageLink}
            role="button"
            className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <ChevronLeftIcon className="-ml-1.5 h-5 w-5" />
            Previous
          </a>
        )}
        {props.startIndex + props.pageSize >= props.totalSize && (
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 opacity-50 shadow-sm hover:bg-gray-50"
            disabled
          >
            Next
            <ChevronRightIcon className="-mr-1.5 h-5 w-5" />
          </button>
        )}
        {props.startIndex + props.pageSize < props.totalSize && (
          <a
            href={props.nextPageLink}
            role="button"
            className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Next
            <ChevronRightIcon className="-mr-1.5 h-5 w-5" />
          </a>
        )}
      </div>
    </nav>
  );
}
