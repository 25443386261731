import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { type Link } from 'type-route';

type Page = {
  name: string;
  link: Link;
  current: boolean;
};

interface BreadcrumbsProps {
  pages: Page[];
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  return (
    <div>
      <nav className="sm:hidden" aria-label="Back">
        <a
          href="#"
          className="flex items-center text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700"
        >
          <ChevronLeftIcon
            className="mr-1 -ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      {props.pages.length > 0 && (
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-3">
            <li key={props.pages[0].name}>
              <div className="flex">
                <a
                  {...props.pages[0].link}
                  className="text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={props.pages[0].current ? 'page' : undefined}
                >
                  {props.pages[0].name}
                </a>
              </div>
            </li>
            {props.pages.slice(1).map((page) => (
              <li key={page.name}>
                <div className="flex">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <a
                    {...page.link}
                    className="ml-3 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </div>
  );
}
