import TimeAgo from 'timeago-react';

import { appResourceName, formatIncarnation } from '../../helpers/util';
import { useListIncarnations } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { IncarnationKebab } from './incarnation_kebab';
import { IncarnationStateBadge } from './incarnation_state_badge';

interface IncarnationTableProps {
  projectId: string;
  appId: string;
}

export function IncarnationTable(props: IncarnationTableProps) {
  const parent = appResourceName(props.projectId, props.appId);
  const { data, mutate } = useListIncarnations({
    parent: parent,
    pageSize: 15,
    pageToken: '',
  });

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  ID
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Commit
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Tool
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Files
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Assets
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {data.incarnations.length == 0 && (
                <tr>
                  <td
                    colSpan={7}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500"
                  >
                    No revisions to display
                  </td>
                </tr>
              )}
              {data.incarnations.map((incarnation) => (
                <tr key={incarnation.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.incarnationDetail({
                        projectId: props.projectId,
                        applicationId: props.appId,
                        incarnationId: incarnation.name.split('/')[5],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {formatIncarnation(incarnation.name.split('/')[5])}
                    </a>
                  </td>
                  <td className="border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <IncarnationStateBadge state={incarnation.state} />
                  </td>
                  <td className="border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {incarnation.source.git.revisionCommit === ''
                      ? '\u2014'
                      : incarnation.source.git.revisionCommit.substring(0, 7)}
                  </td>
                  <td className="border-b border-gray-200 px-6 py-2 text-right text-sm leading-5 text-gray-500">
                    <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                      {incarnation.tool}
                    </span>
                  </td>
                  <td className="border-b border-gray-200 px-6 py-2 text-right text-sm leading-5 text-gray-500">
                    {incarnation.fileCount}
                  </td>
                  <td className="border-b border-gray-200 px-6 py-2 text-right text-sm leading-5 text-gray-500">
                    {incarnation.assetCount}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <TimeAgo
                      datetime={incarnation.createTime.toDate()}
                      opts={{ minInterval: 10 }}
                    />
                  </td>
                  <td className="w-full whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <IncarnationKebab
                      projectId={props.projectId}
                      appId={props.appId}
                      incarnationId={incarnation.name.split('/')[5]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.incarnations.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.incarnations.length}
              startIndex={data.startIndex}
              previousPageLink={routes.incarnationList({
                projectId: props.projectId,
                applicationId: props.appId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.incarnationList({
                projectId: props.projectId,
                applicationId: props.appId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
