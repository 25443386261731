import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import useSWR from 'swr';

import { SecurityTokenService } from '../../proto/annealing/sts/v1/service_connect';

const refreshInterval = 2000;
const transport = createGrpcWebTransport({ baseUrl: '' });

const stsClient = createPromiseClient(SecurityTokenService, transport);
const opts = { refreshInterval: refreshInterval, suspense: true };

async function getUserInfo() {
  return await stsClient.getUserInfo({});
}

export function useGetUserInfo() {
  return useSWR(
    ['annealing.sts.v1.SecurityTokenService/GetUserInfo'],
    ([_m]) => getUserInfo(),
    opts,
  );
}
