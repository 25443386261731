import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';
import { rolloutStateToString } from '../../helpers/util';
import { CircularProgress } from '../core/circular_progress';

interface RolloutStateBadgeProps {
  state: number;
}

export function RolloutStateBadge(props: RolloutStateBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {rolloutStateToString(props.state) === 'Succeeded' && (
        <CheckCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {rolloutStateToString(props.state) === 'Failed' && (
        <ExclamationCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
          aria-hidden="true"
        />
      )}
      {rolloutStateToString(props.state) === 'Pending' && (
        <EllipsisHorizontalCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      {rolloutStateToString(props.state) === 'Running' && (
        <CircularProgress className="mr-2 ml-0.5 h-4 w-4 animate-spin text-blue-500" />
      )}
      {rolloutStateToString(props.state)}
    </span>
  );
}
