import { LinkIcon } from '@heroicons/react/20/solid';

import { gitRepoResourceName } from '../../helpers/util';
import { useGetGitRepository } from '../../hooks/use_delivery_api';
import { GitRepoDeleteModal } from './git_repo_delete_modal';
import { GitRepoStateBadge } from './git_repo_state_badge';

interface GitRepoHeadingProps {
  projectId: string;
  repoId: string;
}

export function GitRepoHeading(props: GitRepoHeadingProps) {
  const name = gitRepoResourceName(props.projectId, props.repoId);
  const { data } = useGetGitRepository({ name: name });
  const repo = data;

  return (
    <div className="my-2 md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-medium text-gray-900">{props.repoId}</h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <GitRepoStateBadge state={repo.state} />
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <LinkIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {repo.uri}
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
        <div className="space-x-3">
          <GitRepoDeleteModal
            projectId={props.projectId}
            repoId={props.repoId}
          />
        </div>
      </div>
    </div>
  );
}
