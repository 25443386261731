import { PlusIcon } from '@heroicons/react/20/solid';
import { Suspense } from 'react';
import { Route } from 'type-route';

import { routes } from '../../router';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { SSHKnownHostTable } from './ssh_known_host_table';

interface SSHKnownHostListProps {
  route: Route<typeof routes.sshKnownHostList>;
}

export function SSHKnownHostList(props: SSHKnownHostListProps) {
  const { route } = props;
  return (
    <div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-semibold text-gray-900">
            SSH known hosts
          </h1>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
          <a
            {...routes.sshKnownHostCreate({
              projectId: route.params.projectId,
            }).link}
            role="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
            New SSH known host
          </a>
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <SSHKnownHostTable projectId={route.params.projectId} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
