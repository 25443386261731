import { useListClusters } from '../../hooks/use_delivery_api';

interface ClusterChooserProps {
  projectId: string;
  register: any;
}

export function ClusterChooser(props: ClusterChooserProps) {
  const { data } = useListClusters({
    parent: `projects/${props.projectId}`,
    pageSize: 100,
    pageToken: '',
  });

  return (
    <select
      {...props.register('cluster')}
      id="cluster"
      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      defaultValue={data.clusters[0].name.split('/')[3]}
    >
      {data.clusters.map((cluster) => (
        <option>{cluster.name.split('/')[3]}</option>
      ))}
    </select>
  );
}
