import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import useSWR from 'swr';

import { DeliveryService } from '../../proto/annealing/delivery/v1/service_connect';

const refreshInterval = 2000;
const transport = createGrpcWebTransport({ baseUrl: '' });

const client = createPromiseClient(DeliveryService, transport);
const opts = { refreshInterval: refreshInterval, suspense: true };

export async function createSSHKey(req: any) {
  return await client.createSshKey(req);
}

async function listSSHKeys(req: any) {
  return await client.listSshKeys(req);
}

async function getSSHKey(req: any) {
  return await client.getSshKey(req);
}

export async function deleteSSHKey(req: any) {
  return await client.deleteSshKey(req);
}

export async function createSSHKnownHost(req: any) {
  return await client.createSshKnownHost(req);
}

async function listSSHKnownHosts(req: any) {
  return await client.listSshKnownHosts(req);
}

async function getSSHKnownHost(req: any) {
  return await client.getSshKnownHost(req);
}

export async function deleteSSHKnownHost(req: any) {
  return await client.deleteSshKnownHost(req);
}

export async function createIgnoreDifference(req: any) {
  return await client.createIgnoreDifference(req);
}

async function listIgnoreDifferences(req: any) {
  return await client.listIgnoreDifferences(req);
}

async function getIgnoreDifference(req: any) {
  return await client.getIgnoreDifference(req);
}

export async function deleteIgnoreDifference(req: any) {
  return await client.deleteIgnoreDifference(req);
}

async function listClusters(req: any) {
  return await client.listClusters(req);
}

async function getCluster(req: any) {
  return await client.getCluster(req);
}

export async function createCluster(req: any) {
  return await client.createCluster(req);
}

export async function deleteCluster(req: any) {
  return await client.deleteCluster(req);
}

async function listAssets(req: any) {
  return await client.listAssets(req);
}

async function getAsset(req: any) {
  return await client.getAsset(req);
}

async function listRollouts(req: any) {
  return await client.listRollouts(req);
}

async function searchRollouts(req: any) {
  return await client.searchRollouts(req);
}

async function listRolloutSteps(req: any) {
  return await client.listRolloutSteps(req);
}

async function listRolloutAssets(req: any) {
  return await client.listRolloutAssets(req);
}

async function getRollout(req: any) {
  return await client.getRollout(req);
}

async function listApplications(req: any) {
  return await client.listApplications(req);
}

async function getApplication(req: any) {
  return await client.getApplication(req);
}

export async function createApplication(req: any) {
  return await client.createApplication(req);
}

export async function updateApplication(req: any) {
  return await client.updateApplication(req);
}

export async function deleteApplication(req: any) {
  return await client.deleteApplication(req);
}

async function listCharts(req: any) {
  return await client.listCharts(req);
}

async function listChartRepositories(req: any) {
  return await client.listChartRepositories(req);
}

async function getChartRepository(req: any) {
  return await client.getChartRepository(req);
}

export async function createChartRepository(req: any) {
  return await client.createChartRepository(req);
}

export async function deleteChartRepository(req: any) {
  return await client.deleteChartRepository(req);
}

async function listGitRepositories(req: any) {
  return await client.listGitRepositories(req);
}

async function getGitRepository(req: any) {
  return await client.getGitRepository(req);
}

export async function createGitRepository(req: any) {
  return await client.createGitRepository(req);
}

export async function deleteGitRepository(req: any) {
  return await client.deleteGitRepository(req);
}

async function listIncarnations(req: any) {
  return await client.listIncarnations(req);
}

async function getIncarnation(req: any) {
  return await client.getIncarnation(req);
}

export function useListSSHKeys(req: any) {
  return useSWR(['listSshKeys', req], ([_m, req]) => listSSHKeys(req), opts);
}

export function useGetSSHKey(req: any) {
  return useSWR(['getSshKey', req], ([_m, req]) => getSSHKey(req), opts);
}

export function useListSSHKnownHosts(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListSshKnownHosts', req],
    ([_m, req]) => listSSHKnownHosts(req),
    opts,
  );
}

export function useGetSSHKnownHost(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetSshKnownHost', req],
    ([_m, req]) => getSSHKnownHost(req),
    opts,
  );
}

export function useListIgnoreDifferences(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListIgnoreDifferences', req],
    ([_m, req]) => listIgnoreDifferences(req),
    opts,
  );
}

export function useGetIgnoreDifference(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetIgnoreDifference', req],
    ([_m, req]) => getIgnoreDifference(req),
    opts,
  );
}

export function useListClusters(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListClusters', req],
    ([_m, req]) => listClusters(req),
    opts,
  );
}

export function useGetCluster(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetCluster', req],
    ([_m, req]) => getCluster(req),
    opts,
  );
}

export function useListAssets(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListAssets', req],
    ([_m, req]) => listAssets(req),
    opts,
  );
}

export function useGetAsset(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetAsset', req],
    ([_m, req]) => getAsset(req),
    opts,
  );
}

export function useListRollouts(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListRollouts', req],
    ([_m, req]) => listRollouts(req),
    opts,
  );
}

export function useSearchRollouts(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/SearchRollouts', req],
    ([_m, req]) => searchRollouts(req),
    opts,
  );
}

export function useGetRollout(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetRollout', req],
    ([_m, req]) => getRollout(req),
    opts,
  );
}

export function useListRolloutSteps(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListRolloutSteps', req],
    ([_m, req]) => listRolloutSteps(req),
    opts,
  );
}

export function useListRolloutAssets(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListRolloutAssets', req],
    ([_m, req]) => listRolloutAssets(req),
    opts,
  );
}

export function useListApplications(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListApplications', req],
    ([_m, req]) => listApplications(req),
    opts,
  );
}

export function useGetApplication(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetApplication', req],
    ([_m, req]) => getApplication(req),
    opts,
  );
}

export function useListIncarnations(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListIncarnations', req],
    ([_m, req]) => listIncarnations(req),
    opts,
  );
}

export function useListGitRepositories(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListGitRepositories', req],
    ([_m, req]) => listGitRepositories(req),
    opts,
  );
}

export function useGetGitRepository(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetGitRepository', req],
    ([_m, req]) => getGitRepository(req),
    opts,
  );
}

export function useListCharts(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListCharts', req],
    ([_m, req]) => listCharts(req),
    opts,
  );
}

export function useListChartRepositories(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/ListChartRepositories', req],
    ([_m, req]) => listChartRepositories(req),
    opts,
  );
}

export function useGetChartRepository(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetChartRepository', req],
    ([_m, req]) => getChartRepository(req),
    opts,
  );
}

export function useGetIncarnation(req: any) {
  return useSWR(
    ['annealing.delivery.v1.DeliveryService/GetIncarnation', req],
    ([_m, req]) => getIncarnation(req),
    opts,
  );
}
