import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { OperationHeading } from './operation_heading';

interface OperationDetailProps {
  route: Route<typeof routes.computeOperationDetail>;
}

export function OperationDetail(props: OperationDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Operations',
      link: routes.computeOperationList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <OperationHeading
            projectId={route.params.projectId}
            operationId={route.params.operationId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
