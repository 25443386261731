import { Suspense } from 'react';
import { type Route } from 'type-route';

import { groups, routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { Tabs } from '../core/tabs';
import { ApplicationHeading } from './application_heading';
import { AssetTable } from './asset_table';
import { IncarnationTable } from './incarnation_table';
import { RolloutTable } from './rollout_table';

interface ApplicationDetailProps {
  route: Route<typeof groups.applicationDetail>;
}

export function ApplicationDetail(props: ApplicationDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Applications',
      link: routes.applicationList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  const tabs = [
    {
      name: 'Overview',
      link: routes.applicationDetail({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: route.name === 'applicationDetail',
    },
    {
      name: 'Incarnations',
      link: routes.incarnationList({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: route.name === 'incarnationList',
    },
    {
      name: 'Rollouts',
      link: routes.rolloutList({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: route.name === 'rolloutList',
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ApplicationHeading
            projectId={route.params.projectId}
            appId={route.params.applicationId} />
        </Suspense>
      </ErrorBoundary>
      <Tabs tabs={tabs} />
      {route.name === 'applicationDetail' && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <AssetTable
              projectId={route.params.projectId}
              appId={route.params.applicationId} />
          </Suspense>
        </ErrorBoundary>
      )}
      {route.name === 'incarnationList' && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <IncarnationTable
              projectId={route.params.projectId}
              appId={route.params.applicationId} />
          </Suspense>
        </ErrorBoundary>
      )}
      {route.name === 'rolloutList' && (
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <RolloutTable
              projectId={route.params.projectId}
              appId={route.params.applicationId}
              showProject={false}
              showApp={false}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  );
}
