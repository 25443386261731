import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { OperationTable } from './operation_table';

interface OperationListProps {
  route: Route<typeof routes.computeOperationList>;
}

export function OperationList(props: OperationListProps) {
  const { route } = props;
  return (
    <div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-semibold text-gray-900">Operations</h1>
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <OperationTable projectId={route.params.projectId} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
