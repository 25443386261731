import { Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';

import { projectResourceName } from '../../helpers/util';
import { createGitRepository } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { createToast } from '../core/toast';
import { SSHKeyChooser } from './ssh_key_chooser';

interface GitRepoCreateProps {
  route: Route<typeof routes.gitRepositoryCreate>;
}

interface GitRepoCreateFormData {
  name: string;
  uri: string;
  authType: string;
  sshKey: string;
}

export function GitRepoCreate(props: GitRepoCreateProps) {
  const { route } = props;
  const { register, watch, handleSubmit } = useForm<GitRepoCreateFormData>();

  const parent = projectResourceName(route.params.projectId);

  const onSubmit = async (data: GitRepoCreateFormData) => {
    try {
      await createGitRepository({
        parent: parent,
        gitRepository: {
          name: '',
          uri: data.uri,
          state: 0,
          authentication: {
            sshKey: data.sshKey
              ? `projects/${route.params.projectId}/sshKeys/${data.sshKey}`
              : '',
          },
        },
        gitRepositoryId: data.name,
      });
      createToast(`Git repository "${data.name}" created.`);
      routes.gitRepositoryDetail({
        projectId: route.params.projectId,
        gitRepositoryId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating git repository: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'Git repositories',
      link: routes.gitRepositoryList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  const authOptions = [
    {
      id: 'none',
      name: 'None',
      description: 'No authentication when accessing the Git repository',
    },
    {
      id: 'ssh-key',
      name: 'SSH key',
      description:
        'SSH public key authenticaiton when accessing the Git repository',
    },
  ];

  const authType = watch('authType');

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            New Git repository
          </h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        A Git repository contains Kubernetes configs for your applications. You
        can automatically sync your applications when you push updates to your
        Kubernetes configs.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Repository name
              </label>
              <div className="mt-1">
                <input
                  {...register('name')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="uri"
                className="block text-sm font-medium text-gray-700"
              >
                URL
              </label>
              <div className="mt-1">
                <input
                  {...register('uri')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Authentication
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                This defines how we will authenticate to the Git repository host
                when cloning and pulling manifest files.
              </p>
            </div>
            <fieldset className="mt-4">
              <legend className="sr-only">Authentication</legend>
              <div className="space-y-5">
                {authOptions.map((v) => (
                  <div key={v.id} className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        {...register('authType')}
                        id={v.id}
                        aria-describedby={`${v.id}-description`}
                        type="radio"
                        defaultChecked={v.id === 'none'}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        value={v.name}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={v.id}
                        className="font-medium text-gray-700"
                      >
                        {v.name}
                      </label>
                      <p id={`${v.id}-description`} className="text-gray-500">
                        {v.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
            {authType === 'SSH key' && (
              <div className="ml-7 mt-6">
                <div className="text-sm">
                  <label
                    htmlFor="sshKey"
                    className="block text-sm font-medium text-gray-700"
                  >
                    SSH key name
                  </label>
                  <ErrorBoundary>
                    <Suspense fallback={<Spinner />}>
                      <SSHKeyChooser
                        projectId={route.params.projectId}
                        register={register}
                      />
                    </Suspense>
                  </ErrorBoundary>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.gitRepositoryList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Attach
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
