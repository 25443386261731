import TimeAgo from 'timeago-react';

import { useListApplications } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { ApplicationKebab } from './application_kebab';
import { AssetHealthStateBadge } from './asset_health_state_badge';
import { AssetSyncStateBadge } from './asset_sync_state_badge';

interface ApplicationTableProps {
  projectId: string;
}

export function ApplicationTable(props: ApplicationTableProps) {
  const { data, mutate } = useListApplications({
    parent: `projects/${props.projectId}`,
    pageSize: 15,
    pageToken: '',
  });
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="whitespace-nowrap border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Health state
                </th>
                <th className="whitespace-nowrap border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Sync state
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Cluster
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Namespace
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Assets
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Refreshed
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.applications.length == 0 && (
                <tr>
                  <td
                    colSpan={8}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No applications to display
                  </td>
                </tr>
              )}
              {data.applications.map((application) => (
                <tr key={application.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.applicationDetail({
                        projectId: props.projectId,
                        applicationId: application.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {application.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <AssetHealthStateBadge state={application.healthState} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <AssetSyncStateBadge state={application.syncState} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <a
                      {...routes.clusterDetail({
                        projectId: props.projectId,
                        clusterId: application.target.cluster.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {application.target.cluster.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {application.target.namespace.length === 0
                      ? '\u2014'
                      : application.target.namespace}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-right text-sm leading-5 text-gray-500">
                    {application.assetCount}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <TimeAgo
                      datetime={application.refreshTime.toDate()}
                      opts={{ minInterval: 10 }}
                    />
                  </td>
                  <td className="w-full border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <ApplicationKebab
                      projectId={props.projectId}
                      appId={application.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.applications.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.applications.length}
              startIndex={data.startIndex}
              previousPageLink={routes.applicationList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.applicationList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
