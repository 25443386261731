import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { IgnoreDifferenceDeleteModal } from './ignore_difference_delete_modal';
import { IgnoreDifferenceInfo } from './ignore_difference_info';

interface IgnoreDifferenceDetailProps {
  route: Route<typeof routes.ignoreDifferenceDetail>;
}

export function IgnoreDifferenceDetail(props: IgnoreDifferenceDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Ignore differences',
      link: routes.ignoreDifferenceList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="my-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            {route.params.ignoreDifferenceId}
          </h1>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-6">
          <IgnoreDifferenceDeleteModal
            projectId={route.params.projectId}
            ignoreDifferenceId={route.params.ignoreDifferenceId}
          />
        </div>
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <IgnoreDifferenceInfo
            projectId={route.params.projectId}
            ignoreDifferenceId={route.params.ignoreDifferenceId}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
