import { DigitalOceanIcon } from '../icons/digitalocean_icon';
import { EKSIcon } from '../icons/eks_icon';
import { GKEIcon } from '../icons/gke_icon';
import { K3sIcon } from '../icons/k3s_icon';
import { KubernetesIcon } from '../icons/kubernetes_icon';
import { OKDIcon } from '../icons/okd_icon';
import { OpenShiftIcon } from '../icons/openshift_icon';

interface ClusterDistributionBadgeProps {
  distribution: string;
}

export function ClusterDistributionBadge(props: ClusterDistributionBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {props.distribution === 'K3s' && (
        <K3sIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          ariaHidden="true"
        />
      )}
      {props.distribution === 'DOKS' && (
        <DigitalOceanIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          ariaHidden="true"
        />
      )}
      {props.distribution === 'GKE' && (
        <GKEIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          ariaHidden="true"
        />
      )}
      {props.distribution === 'EKS' && (
        <EKSIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          ariaHidden="true"
        />
      )}
      {props.distribution === 'OpenShift' && (
        <OpenShiftIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          ariaHidden="true"
        />
      )}
      {props.distribution === 'OKD' && (
        <OKDIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          ariaHidden="true"
        />
      )}
      {props.distribution === 'Kubernetes' && (
        <KubernetesIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          ariaHidden="true"
        />
      )}
      {props.distribution === '' ? '\u2014' : props.distribution}
    </span>
  );
}
