import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Main } from './components/main';
import { RouteProvider } from './router';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <RouteProvider>
      <Main />
    </RouteProvider>
  </StrictMode>,
);
