import { Suspense } from 'react';
import { type Route } from 'type-route';

import { formatIncarnation } from '../../helpers/util';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { IncarnationInfo } from './incarnation_info';

interface IncarnationDetailProps {
  route: Route<typeof routes.incarnationDetail>;
}

export function IncarnationDetail(props: IncarnationDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Applications',
      link: routes.applicationList({
        projectId: route.params.projectId,
      }).link,
      current: false,
    },
    {
      name: route.params.applicationId,
      link: routes.applicationDetail({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: false,
    },
    {
      name: 'Incarnations',
      link: routes.incarnationList({
        projectId: route.params.projectId,
        applicationId: route.params.applicationId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-medium text-gray-900">
            {formatIncarnation(route.params.incarnationId)}
          </h2>
        </div>
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <IncarnationInfo
              projectId={route.params.projectId}
              appId={route.params.applicationId}
              revisionId={route.params.incarnationId}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}
